import React, { memo, useRef } from 'react';
import { TermsofService, PrivacyPolicy } from './TermsofService';
import icon from '../../assets/icons/closeBlack.svg';
import './termsAndPolicy.scss';
import { triggerCloseModal } from '../../utils/modal.util';

const scrollToNode = (node) => {
  node.scrollIntoView({ behavior: 'smooth' });
};

function TermsAndPolicy({ termsType }) {
  const terms = termsType === 'Privacy' ? PrivacyPolicy : TermsofService;
  const termsList = terms.content;
  const termRef = Array(termsList).fill(useRef(null));

  return (
    <div className="col-12 materialCard changeCredCont popup-container terms-of-service" id="terms-of-service">
      <div className="row" style={{ margin: '0' }}>
        <div className="terms-left-panel col-4 flex-col">
          {termsList.map((d, index) => (
            d.buttonText ? (
              <div
                key={index}
                className="left-panel-button flex-row-align-center"
                onClick={() => scrollToNode(termRef[index])}
                onKeyDown={null}
                role="button"
                tabIndex="0"
              >
                <div className="index-number">{index}</div>
                <div className="index-name">{d.buttonText}</div>
              </div>
            ) : null
          ))}
        </div>
        <div className="terms-right-panel col-8">
          <div className="col-12 popupHeader extra-padding">
            <h2>{terms.title}</h2>
            <h6>{terms.subtext}</h6>
            <div className="fa terms-fa-times" onClick={() => triggerCloseModal()} aria-hidden="true">
              <img src={icon} alt="close icon" aria-hidden="true" />
            </div>
          </div>
          <div className="terms-listed">
            {termsList.map((d, index) => (
              <div className="term" key={index} id="accepting-the-terms" ref={(node) => { termRef[index] = node; }}>
                {d.buttonText
                  ? (
                    <div className="head flex-row-align-center">
                      <div className="head-caption">{`${index}. ${d.buttonText}`}</div>
                      <div className="line-hor" />
                    </div>
                  )
                  : null}
                <div className="content">
                  {d.content}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(TermsAndPolicy);
