/* eslint-disable import/no-anonymous-default-export */
import { API } from 'aws-amplify';
import { onError } from './errorLib';
import { AlertError, dismissAllToast } from '../components/Alerts';
import { CLIENT_TIMEZONE } from '../utils/date.util';
const EXEMPTED_STATUS_CODES = [200, 202, 102, 432, 431, 433];
// TODO: We can handle response here;
const responseHandler = (resp, path) => {
  if (resp && !EXEMPTED_STATUS_CODES.includes(resp.statusCode)) {
    if (resp?.body) onError(new Error(`RSEWB: path: ${path} response: ${JSON.stringify(resp.body)}`));
  }
  return resp;
};

export const fetchWithTimeout = async (resource, options = {}) => { // Check for internet connectivity
  const { timeout = 10000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, {
    mode: 'no-cors',
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);
  return response;
};

const errorHandler = () => {
  dismissAllToast();
  AlertError({
    head: 'Please check your internet connection and reload app',
  });
};

export default {
  get: (apiName, path, init) => API.get(apiName, path, init).then((resp) => responseHandler(resp, path, init)).catch(errorHandler),
  put: (apiName, path, init) => API.put(apiName, path, init).then((resp) => responseHandler(resp, path, init)).catch(errorHandler),
  post: (apiName, path, init) => {
    const postPayloadWithTimezone = { ...(init || { body: {} }) };
    postPayloadWithTimezone.body.clientTimeZone = CLIENT_TIMEZONE;
    return API.post(apiName, path, postPayloadWithTimezone).then((resp) => responseHandler(resp, path, init)).catch(errorHandler);
  },
  del: (apiName, path, init) => API.del(apiName, path, init).then((resp) => responseHandler(resp, path, init)).catch(errorHandler),
};

