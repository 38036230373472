import * as Sentry from '@sentry/react';
// Uncomment if you want performance trackig
import { Integrations } from '@sentry/tracing';
import { IS_NOT_PRODUCTION } from '../constants';

export function initSentry() {
  if (IS_NOT_PRODUCTION) {
    return;
  }

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    ignoreErrors: [
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      'InvalidStateError',
    ],
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.8,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    // tracesSampleRate: isLocal ? 0 : 0.1,
  });
}

export function logError(error, errorInfo = null) {
  if (IS_NOT_PRODUCTION) {
    return;
  }

  Sentry.withScope((scope) => {
    scope.setExtras(errorInfo);
    const userInfo = localStorage.getItem(`persist:${process.env.REACT_APP_WEB_URL}`);
    const parsedUserInfo = JSON.parse(userInfo || '{}');
    const info = JSON.parse(parsedUserInfo.UserSettings || '{}');
    Sentry.setExtra('info', `${info.user?.email}:${info.user?.companyEmail}`);
    Sentry.captureException(error);
  });
}

export function onError(error) {
  let errorInfo = {};
  let message = error.toString();

  // As Amplify Auth does not throw error object,
  // And Sentry needs an error object so we are using it
  if (!(error instanceof Error) && error.message) {
    errorInfo = error;
    message = error.message;
    // eslint-disable-next-line no-param-reassign
    error = new Error(message);

    // In Amplify API errors we get actual Error object.
  } else if (error.config && error.config.url) {
    errorInfo.url = error.config.url;
  }

  logError(error, errorInfo);

  /*
    Add UI component to show errors gracefully
    alert(message);
  */
}
