import { FETCH_SETTING, FETCH_SETTING_FAILURE, FETCH_SETTING_SUCCESS, FETCH_USER, FETCH_USER_FAILURE, FETCH_USER_SUCCESS, UPDATE_USER } from ".";
import { getAccountSettings, getUser } from "../../../services/user";
import { SPINNER_COUNTER } from "../type";

export function setAccountSettingThunk({ accountId }, increaseCount) {
    return (dispatch) => {
      dispatch({ type: FETCH_SETTING, payload: { accountId }});
      getAccountSettings({ id: accountId })
        .then((resp) => {
          dispatch({ type: FETCH_SETTING_SUCCESS, payload: resp });
        })
        .catch((error) => {
          dispatch({ type: FETCH_SETTING_FAILURE, payload: error.message });
        })
        .finally(() => increaseCount && dispatch({ type: SPINNER_COUNTER }));
    };
  }

  export function setUserThunk(increaseCount) {
    return (dispatch) => {
      dispatch({ type: FETCH_USER });
      getUser({ })
        .then((resp) => {
          dispatch({ type: FETCH_USER_SUCCESS, payload: resp });
        })
        .catch((error) => {
          dispatch({ type: FETCH_USER_FAILURE, payload: error.message });
        })
        .finally(() => increaseCount && dispatch({ type: SPINNER_COUNTER }));
    };
  }


export function setUserFailure(error) {
    return { type: FETCH_USER_FAILURE, payload: error };
  }

  export function setUpdateUser(data) {
    return (dispatch) => dispatch({ type: UPDATE_USER, payload: data });
  }