import React, { memo } from 'react';
import { closeBlack } from '../../assets';
import { triggerCloseModal } from '../../utils/modal.util';

function CloseButton({
  onClick = triggerCloseModal, icon = closeBlack, buttonStyle = {}, buttonId = null,
  closeIconEffectCss = '',
}) {
  return (
    <button
      type="button"
      className="closeBtn"
      onClick={() => onClick()}
      style={buttonStyle}
      id={buttonId}
    >
      <img src={icon} alt="close icon" aria-hidden="true" id="closeBtnImageId" className={closeIconEffectCss} />
    </button>
  );
}

export default memo(CloseButton);
