import React, {
  memo, useMemo,
} from 'react';
import CautiousIcon from './CautiousIcon';
import MaterialIcon from './MaterialIcon';
import CloseButton from './CloseButton';
import { useHighlightWithCssEffectHook } from '../../CustomHooks/useHighlightWithCssEffectHook';
import { voidFunction } from '../../utils';

function PopupHeader({
  title, onClick, icon, actionDescription = '', buttonId = '',
  buttonStyle = {}, handleExpandIconClick = () => { /* do something */ }, expandIcon = '',
  showCloseButton = true, parentContainerClassName = 'custom-modal-container', parentContainerId, className = '',
}) {
  const [closeIconEffectCss] = useHighlightWithCssEffectHook({ parentContainerClassName, parentContainerId });

  const canBeMaximized = useMemo(() => expandIcon === 'maximize', [expandIcon]);
  const colorForText = useMemo(() => (showCloseButton ? '#000' : '#fff'), [showCloseButton]);
  return (
    <div
      className={`popupHeader flex align-items-center justify-content-between inheritWidth ${className}`}
      tabIndex="0"
      role="button"
      onKeyDown={voidFunction}
      onClick={() => canBeMaximized && handleExpandIconClick()}
      style={{
        cursor: canBeMaximized ? 'pointer' : 'default',
      }}
    >
      <h3
        className="flex"
        style={{
          color: colorForText,
        }}
      >
        {title || ''}
        {actionDescription && (
        <>
          &nbsp;
          {' '}
          <CautiousIcon dataText={actionDescription} dataId={`deleteAction-${title}`} />
        </>
        )}
      </h3>
      <div className="flex align-items-center">
        {
          expandIcon && (
          <MaterialIcon
            icon={expandIcon}
            style={{ fontSize: canBeMaximized ? '12px' : '16px', marginRight: '2px', color: colorForText }}
            onClick={() => handleExpandIconClick()}
          />
          )
        }
        {
          showCloseButton && <CloseButton icon={icon} onClick={onClick} buttonId={buttonId} buttonStyle={buttonStyle} closeIconEffectCss={closeIconEffectCss} />
        }
      </div>
    </div>
  );
}

export default memo(PopupHeader);
