import React, { memo } from 'react';
import { orientation } from '../../assets';
import './index.scss';

function OrientationScreen() {
  return (
    <div className="app-orientation-message">
      <div className="app-orientation-content">
        <img
          className="app-orientation-icon"
          src={orientation}
          alt="rotate screen"
        />
        <span>Please rotate your device</span>
      </div>
    </div>
  );
}

export default memo(OrientationScreen);
