import React from 'react';
import { HOW_IT_WORKS_LABEL } from './constants';
import MaterialIcon from '../../Common/MaterialIcon';

export const HowDoesItWork = ({ action, text = '' }) => (
  <div className="float-right inlineFlex howDoesItWork">
    <button className="italic align-items-center ctaUnderscore flex italic" type="button" onClick={action}>
      <MaterialIcon icon="lightbulb" />
      {' '}
      <>{text || 'How does it work?'}</>
    </button>
  </div>
);

export default function Label({
  text = '', isMandatory = false, style = {}, extraClass = '',
}) {
  return (
    <div className={`label ${isMandatory ? 'required' : ''} ${extraClass}`} style={style}>
      {text}
      {HOW_IT_WORKS_LABEL[text] && <HowDoesItWork action={HOW_IT_WORKS_LABEL[text].action} />}
    </div>
  );
}
