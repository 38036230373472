import * as React from 'react';
import * as ReactDOM from 'react-dom';
import FullScreenSpinner from '../components/Spinner';
import Alert from '../components/Modal/AlertModal';
import Modal from '../components/Modal/AppModal';
import TermsAndPolicy from '../components/Popups/TermsAndPolicy';
import CustomiseTableColumm from '../components/Popups/CustomiseTableColumn';
import '../components/Popups/index.scss';

export const triggerCloseModal = (buttonId = 'close-custom-modal') => {
  const el = document.getElementById(buttonId);
  if (el) {
    el.click();
  }
};

// Blurs the childrens in the body
export const blurChildren = (body, blur) => {
  body.childNodes.forEach((c) => {
    if (!(c instanceof HTMLElement)) return;
    /* eslint no-param-reassign: ["error",
    { "props": true, "ignorePropertyModificationsFor": ["c"] }] */
    c.style.filter = blur ? 'blur(3px)' : '';
  });
};

export const disableChildren = (body, disable) => {
  body.childNodes.forEach((c) => {
    if (!(c instanceof HTMLElement)) return;
    /* eslint no-param-reassign: ["error",
    { "props": true, "ignorePropertyModificationsFor": ["c"] }] */
    c.style.pointerEvents = disable ? 'none' : '';
  });
};

// Utility function for rendering modal component with asyn behaviour
export const showDialogAsync = async ({
  nodeFn,
  // doBlur,
  modalId = '',
  parentId = '',
}) => {
  let removeSelf = (x) => x;
  let ele = document.body;
  if (parentId) {
    ele = document.getElementById(parentId);
  }
  const promise = new Promise((resolve, reject) => {
    // if (doBlur) {
    //   blurChildren(ele, doBlur);
    // }
    const container = ele.appendChild(document.createElement('div'));
    if (modalId) container.id = modalId;
    removeSelf = () => {
      ReactDOM.unmountComponentAtNode(container);

      // if (doBlur) {
      //   blurChildren(ele, false);
      // }

      container.remove();
    };
    ReactDOM.render(nodeFn(resolve, reject), container);
  });
  promise.then(removeSelf, removeSelf);
  return promise;
};

// TODO: Change the params function to object to avoid passing unnecessary
// props to modal component
// utility function to display differnet components in a modal
export const showModal = async (
  {
    node,
    modalId = '',
    className = '',
    extraClass = '',
    beforeClose = () => { /* do something */ },
    title = '',
    actionDescription = '',
    showCloseIcon = false,
    closeModalOnOverlayClick = false,
    closeModalOnEsc = false,
    buttonId = 'close-custom-modal',
  },
) => showDialogAsync({
  nodeFn: (resolve, reject) => (
    <Modal
      closeModalOnOverlayClick={closeModalOnOverlayClick}
      closeModalOnEsc={closeModalOnEsc}
      onConfirm={async () => {
        /* eslint no-unused-expressions: 0 */
        resolve();
        beforeClose && (await beforeClose());
      }}
      modalId={modalId}
      onCancel={reject}
      isOpen
      className={className}
      extraClass={extraClass}
      title={title}
      actionDescription={actionDescription}
      showCloseIcon={showCloseIcon}
      buttonId={buttonId}
    >
      {node}
    </Modal>
  ),
});

// Utility function to render full-screen spinner
export const showFullScreenSpinner = async (fn, parentId = '') => showDialogAsync(
  {
    nodeFn: (resolve, reject) => (
      <FullScreenSpinner
        functionReturningPromise={fn}
        onResolve={resolve}
        onReject={reject}
      />
    ),
    parentId,
  },
);

export const showAlertModal = async (
  {
    type = 'confirmation',
    title = 'Alert',
    primaryText = 'Yes',
    secondaryText = 'Cancel',
    onYes = null,
    onNo = null,
    secondaryContext = '',
    primaryContext = '',
    extraClassName = '',
    actionDescription = '',
    showCloseIcon = true,
    isRequiredToCallTriggerCloseModal,
  },
) => showModal({
  node: <Alert
    type={type}
    primaryText={primaryText}
    secondaryText={secondaryText}
    secondaryContext={secondaryContext}
    primaryContext={primaryContext}
    onNo={onNo}
    onYes={onYes}
    isRequiredToCallTriggerCloseModal={isRequiredToCallTriggerCloseModal}
  />,
  modalId: 'alert-modal',
  extraClass: `custom-modal--alert ${extraClassName}`,
  title,
  actionDescription,
  showCloseIcon,
});

export const lazyComponentLoader = (lazyComponent, numberOfRetriesLeft = 3, interval = 1500) => new Promise((resolve, reject) => {
  lazyComponent()
    .then(resolve)
    .catch(() => {
      setTimeout(() => {
        if (numberOfRetriesLeft === 1) {
          // reject('maximum retries exceeded');
          showAlertModal({
            type: 'alert',
            title: 'A new version of app is available, please reload the page.',
            primaryText: 'Reload',
            onYes: async () => window.location.reload(),
            showCloseIcon: false,
          });
          return;
        }

        // Passing on "reject" is the important part
        lazyComponentLoader(lazyComponent, numberOfRetriesLeft - 1, interval).then(resolve, reject);
      }, interval);
    });
});

export const showTermsofService = async () => showModal({
  node: <TermsAndPolicy />,
});

export const showPrivacyPolicy = async () => showModal({
  node: <TermsAndPolicy termsType="Privacy" />,
});

export const showCustomiseTableColummPopup = async (columnConfiguration) => {
  await showModal({
    node: <CustomiseTableColumm {...columnConfiguration} />,
    modalId: 'customiseTableColumn-modal',
    extraClass: 'customiseTableColumn-modal',
  });
};