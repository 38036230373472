import {
  FETCH_DEVICE_PROFILE_ACTIVITY, FETCH_DEVICE_PROFILE_ACTIVITY_FAILURE, FETCH_DEVICE_PROFILE_ACTIVITY_SUCCESS,
  RESET_DEVICE_PROFILE_ACTIVITY, FETCH_NEXT_DEVICE_PROFILE_ACTIVITY,
} from '../../action/Device/index';

const constructIndividualProfileActivityNewState = ({ action, curState, loadingState }) => {
  const { items } = action.payload;
  const { items: curActivities = [] } = curState;
  return {
    ...curState,
    isLoading: loadingState,
    items: curActivities.concat(items),
  };
};

const initialState = {
  isLoading: false,
  xId: '',
  accountId: '',
  items: [],
  error: '',
  requestId: '',
  totalCount: 0,
};

export const deviceActivityReducer = (curState = initialState, action = { payload: '', action: '' }) => {
  let newState;
  const { type, payload } = action;
  switch (type) {
    case RESET_DEVICE_PROFILE_ACTIVITY:
      newState = { ...initialState };
      break;
    case FETCH_DEVICE_PROFILE_ACTIVITY:
      newState = {
        ...curState,
        xId: payload.xId,
        isLoading: true,
        requestId: payload.requestId,
      };
      break;
    case FETCH_DEVICE_PROFILE_ACTIVITY_SUCCESS: {
      const { xId, totalCount, items, requestId } = payload;
      if (xId === curState.xId && requestId === curState.requestId) {
        newState = {
          ...curState,
          isLoading: false,
          items,
          totalCount,
        };
      } else {
        newState = curState;
      }
      break;
    }
    case FETCH_DEVICE_PROFILE_ACTIVITY_FAILURE:
      newState = {
        ...curState,
        error: payload.error,
        isLoading: false,
      };
      break;
    case FETCH_NEXT_DEVICE_PROFILE_ACTIVITY: {
      const { xId } = payload;
      if (xId === curState.xId) {
        newState = constructIndividualProfileActivityNewState({ action, curState, loadingState: false });
      } else {
        newState = curState;
      }
      break;
    }
    default:
      newState = curState;
  }

  return newState;
};
