export const SETTING_YOU_ROUTE = '/settings/projects';

export const APP_UNAUTHORIZE_ROUTES = [
    {
      route: '/login', name: 'Login',
    }, {
      route: '/signup', name: 'Signup',
    }, {
      route: '/signupComplete', name: 'Signup Complete',
    }, {
      route: '/resetPassword', name: 'Reset Password',
    },
  ];
  
export const APP_ROUTES = [
    {
      icon: 'home',
      route: '/',
      name: 'Dashboard',
    },
    { icon: 'smartphone', route: '/devices', name: 'Devices' },
    { icon: 'settings', route: '/settings', name: 'Risk Configuration' },
  ];
  
export const SETTING_ITEM = {
    icon: 'settings', route: SETTING_YOU_ROUTE, name: 'Settings',
  };