import { add, differenceInDays, format } from "date-fns";
import { fetchReport } from "../../services/v2.api";
import { setAccountSettingThunk, setUserThunk } from "./Account/creator";
import { SET_DASHBOARD_VIEW, USER_ACCESS_DETAILS, USER_AUTHENTICATED, USER_LOGGED_OUT } from "./type";

export const setUserAuthenticated = () => ({ type: USER_AUTHENTICATED });
export const setUserAccessDetails = (user) => ({ type: USER_ACCESS_DETAILS, payload: user });

export function resetStore() {
    return {
      type: USER_LOGGED_OUT,
    };
  }

export function setRequiredDataThunk({
    accountId
  }) {
    return (dispatch) => {
      dispatch(setAccountSettingThunk({ accountId }, true)); // 1);
      dispatch(setUserThunk(true)); // 1);
    };
  }

export function setDashboardData({
    accountId, lId, startDate, endDate, activeSelection, isCustomDate,
  }) {
    return (dispatch) => {
      dispatch({ type: SET_DASHBOARD_VIEW, payload: { startDate, endDate, activeSelection, isCustomDate, isLoading: true }});
      fetchReport({ accountId, lId, range: { startDate, endDate } }).then((res) => {
        const { resultArr, stats } = res;
        const result = [];
        let pointer = 0;
        resultArr.sort((a, b) => new Date(a._id) - new Date(b._id));
        const firstItem = Math.min(new Date(resultArr?.[0]?._id || startDate).getTime(), startDate);
        const days = differenceInDays(endDate, firstItem);
        for (let index = 0; index <= days; index++) {
          const day = format(add(firstItem, { days: index }), "yyyy-L-d");
          if (resultArr?.[pointer]?._id === day) {
            result[index] = resultArr[pointer];
            pointer += 1;
          } else {
            result[index] = { _id: day };
          }
        }
        dispatch({ type: SET_DASHBOARD_VIEW, payload: { resultArr: result, stats, isLoading: false }});
      }).catch(() => dispatch({ type: SET_DASHBOARD_VIEW, payload: { isLoading: false }}))
    };
  }