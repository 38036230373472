import { defineds } from "../../utils/date.util";
import { SET_DASHBOARD_VIEW } from "../action/type";

const initialState = {
  activeSelection: 'Month',
  startDate: new Date(defineds.startOfMonth).getTime(),
  endDate: new Date(defineds.endOfToday).getTime(),
  isCustomDate: false,
  resultArr: [],
  stats: {},
  isLoading: NaN,
};
export const dashboardReducer = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  switch (action.type) {
    case SET_DASHBOARD_VIEW: {
      newState = {
        ...curState,
        ...action.payload,
      };
      break;
    }
    default:
      newState = curState;
  }

  return newState;
};

