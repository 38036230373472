import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { fireEventOnEnter } from '../../utils';
import MaterialIcon from '../Common/MaterialIcon';
import './index.scss';

const Checkbox = ({
  checked, onCheckBoxClick, name, disabled, type,
}) => {
  const getCheckedIcon = useMemo(() => (type === 'circle' ? 'check_circle' : 'check_box'), [type]);
  const getUnCheckedIcon = useMemo(() => (type === 'circle' ? 'radio_button_unchecked' : 'check_box_outline_blank'), [type]);
  const [hovered, setHovered] = useState(false);
  const getIconColor = useMemo(() => ((checked || hovered) ? '#4381DB' : '#565758'), [checked, hovered]);
  return (
    <div
      name={name}
      style={{ pointerEvents: disabled ? 'none' : 'auto' }}
      className={`checkboxContainer ${type}`}
      onClick={(event) => {
        event.stopPropagation();
        onCheckBoxClick(!checked);
      }}
      onKeyDown={(e) => fireEventOnEnter(e, () => {
        e.stopPropagation();
        onCheckBoxClick(!checked);
      })}
      onMouseEnter={() => setHovered(!hovered)}
      onMouseLeave={() => setHovered(!hovered)}
      tabIndex={0}
      role="button"
    >
      <MaterialIcon
        icon={checked ? getCheckedIcon : getUnCheckedIcon}
        style={{
          color: disabled ? '#C6C6C6' : getIconColor,
        }}
        type="material"
      />
    </div>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string,
};

// Same approach for defaultProps too
Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  type: 'box',
  name: '',
};

export default Checkbox;
