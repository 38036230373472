export const MATERIAL_ICON = {
  send: 'send',
  copy: 'file_copy',
  done: 'done',
  download: 'download',
  more_vert: 'more_vert',
  visibility: 'visibility',
  link: 'link',
  reply: 'reply',
  north: 'north',
  south: 'south',
  edit: 'edit',
  mark_email_read: 'mark_email_read',
  unsubscribe: 'unsubscribe',
  logout: 'logout',
  chat_bubble: 'chat_bubble',
  filter: 'filter_alt',
  filterList: 'filter_list',
  contact_support: 'help_outline',
  lock_open: 'lock_open',
  refresh: 'refresh',
  lock: 'lock',
  unlock: 'lock_open',
  delete: 'delete',
  snooze: 'snooze',
  person_add: 'person_add',
  phone: 'phone',
  user: 'person',
  arrowCircleRight: 'arrow_circle_right',
  infoIcon: 'info',
  chevron_right: 'chevron_right',
  chevron_left: 'chevron_left',
  arrow_drop_down: 'arrow_drop_down',
  arrow_drop_up: 'arrow_drop_up',
  expand_more: 'expand_more',
  expand_less: 'expand_less',
  close: 'close',
  drag_indicator: 'drag_indicator',
  warning: 'warning',
  cancel: 'cancel',
  work: 'work',
  groups: 'groups',
  business: 'business',
  email: 'email',
  local_offer: 'local_offer',
  remove: 'remove',
  add: 'add',
  arrow_back: 'arrow_back',
  arrow_forward: 'arrow_forward',
  east: 'east',
  star: 'star',
  star_border: 'star_border',
  bolt: 'bolt',
  mic: 'mic',
  mic_off: 'mic_off',
  edit_note: 'edit_note',
  play_arrow: 'play_arrow',
  addCircle: 'add_circle',
  openInNew: 'open_in_new',
  scheduleSend: 'schedule_send',
  schedule: 'schedule',
  watchLater: 'watch_later',
  location: 'location_on',
  home: 'home',
  money: 'attach_money',
  accountBalance: 'account_balance',
  minimize: 'minimize',
  maximize: 'maximize',
  check_box_outline_blank: 'check_box_outline_blank',
  check_box: 'check_box',
  check_circle: 'check_circle',
  radio_button_unchecked: 'radio_button_unchecked',
  dialpad: 'dialpad',
  sync: 'sync',
  unsync: 'sync_lock',
  check: 'check',
  dashboardCustomize: 'dashboard_customize',
  cancelScheduled: 'cancel_schedule_send',
  highlightOff: 'highlight_off',
  warningAmber: 'warning_amber',
  list: 'list',
  dateRange: 'date_range',
  west: 'west',
  language: 'language',
  missedCall: 'phone_missed',
  call_end: 'call_end',
  dollar: 'attach_money',
  barChart: 'bar_chart',
  lineChart: 'show_chart',
  view_agenda: 'view_agenda',
  openInFull: 'open_in_full',
  closeFullScreen: 'close_fullscreen',
  arrow_outward: 'arrow_outward',
  receipt: 'receipt',
  mark_chat_read: 'mark_chat_read',
  settings: 'settings',
  payment: 'payment',
  rocket_launch: 'rocket_launch',
  campaign: 'campaign',
  keyboard_double_arrow_right: 'keyboard_double_arrow_right',
  keyboard_double_arrow_left: 'keyboard_double_arrow_left',
  fingerprint: 'fingerprint',
};

export const COLORICONS = {
  campaign: '#001429',
  do_not_disturb_on_total_silence: '#212121',
  email: '#1E78F0',
  do_not_disturb_on: '#1E78F0',
  do_not_disturb_off: '#1E78F0',
  arrow_outward: '#1E78F0',
  phone: '#70B738',
  perm_phone_msg: '#70B738',
  edit_note: '#6B4F7C',
  public: '#6B4F7C',
  history: '#6B4F7C',
  delete: '#DE6969',
  unsync: '#DE6969',
  person_add: '#1E78F0',
  settings: '#1E78F0',
  edit: '#6BA63C',
  accountBalance: '#C2B53D',
  snooze: '#7879F1',
  home: '#D4B6C2',
  location: '#DE6969',
  watchLater: 'rgb(88, 87, 87)',
  play_arrow: '#1E78F0',
  pause: '#1E78F0',
  local_offer: '#F8CF40',
  bolt: '#fff',
  scheduleSend: '#7879F1',
  download: '#1E78F0',
  description: '#1E78F0',
  default: '#565758',
  check: '#1E78F0',
  dashboardCustomize: '#1E78F0',
  block: '#DE6969',
  language: '#FFB618',
  timeline: '#FFB618',
  business_center: '#FFB618',
  refresh: '#FFB618',
  addCircle: '#1E78F0',
  west: '#1E78F0',
  activityIconColor: '#1E78F0',
  launch: '#1E78F0',
  sync: '#1E78F0',
  dollar: '#FFB618',
  receipt: '#FFB618',
  infoIcon: '#1E78F0',
  notifications: '#1E78F0',
  mail: '#1E78F0',
  filter_none: '#1E78F0',
  barChart: '#3874cb',
  lineChart: '#3874cb',
  drag_indicator: '#3874cb',
  groups: '#1E78F0',
  calendar_today: '#FFB618',
  lightbulb: '#FFB618',
  open_in_new: '#1E78F0',
  mark_chat_read: '#1E78F0',
  lock: '#1E78F0',
  unsubscribe: '#DE6969',
  vpn_key: '#1E78F0',
  cancel_schedule_send: '#DE6969',
  search: '#1E78F0',
  payment: '#1E78F0',
  rocket_launch: '#fff',
  tag: '#FFB618',
  phone_callback: '#70B738',
  partner_exchange: '#FFB618',
  fingerprint: '#1E78F0',
  login: '#FFB618',
  today: '#70B738',
};
