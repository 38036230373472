import { cleanObject } from '.';
import { DEVICE_ATTRIBUTE } from '../constants/datakey.constant';
import { OPERAND_ENUM } from '../constants/filter.constant';
import { isArray } from './dataType.util';

const EXCLUDE_TILES = ['All', 'Total'];

const isValidValue = (val) => ![undefined, null, ''].includes(val);

export const generateQueryParamsFromFilterV2 = (filter, search) => {
  const params = {
    query: {
      conditions: filter.selectedView.filters.map((d) => {
        // Dev Note: Transform query for async options
        if ([DEVICE_ATTRIBUTE.Device_Brand, DEVICE_ATTRIBUTE.IP_Country, DEVICE_ATTRIBUTE.IdentX_SDK].includes(d.filter)) {
          return {
            ...d, value: isArray(d.value) ? d.value.map((item) => item.value) : d?.value?.value,
          };
        }
        return d;
      }).filter((d) => {
        if ([OPERAND_ENUM.isEmpty, OPERAND_ENUM.isNotEmpty].includes(d.operator)) return true;
        return d.filter && isValidValue(d.value);
      }),
      operator: filter.selectedView.operator,
    },
    sort: { [filter.sortingOption?.id]: filter.direction ? 1 : -1 },
    search,
  };
  [0, 1].includes(params.query.conditions.length) && (params.query.operator = 'And');
  return cleanObject(params);
};

export const generateQueryParamsFromFilterV2WithTile = (filter, search, tile, keyword = 'stage') => {
  const params = generateQueryParamsFromFilterV2(filter, search);
  if (tile && !EXCLUDE_TILES.includes(tile)) {
    params.query.conditions = [...params.query.conditions, {
      filter: keyword || 'stage',
      operator: OPERAND_ENUM.is,
      value: tile,
    }];
  }
  return params;
};

export const generateProjectionExpressionV2 = (projection) => projection.reduce((a, b) => {
  // eslint-disable-next-line no-param-reassign
  a[b.value] = 1;
  return a;
}, {});

export const generateQueryStringFromObject = (object) => {
  const queryString = [];
  Object.keys(object).forEach((item) => {
    if (object[item]) queryString.push(`${item}=${encodeURIComponent(object[item])}`);
  });
  return queryString.join('&');
};
