import API from '../libs/amplify.lib';
import { API_NAME } from '../constants';
import { generateProjectionExpressionV2, generateQueryParamsFromFilterV2WithTile } from '../utils/query.util';
import { parseResponse } from '../libs/response.lib';

/**
 *
 * @param {*} field - stage, taskName
 * @param {*} query - { conditions: [], operator: 'And/Or' }
 * @returns
 */
export const queryTilesData = async ({
  entity, accountId, query, field, operation = 'tile',
}) => API.post(API_NAME, `/${entity}?operation=${operation}`, {
  body: {
    accountId, field, query,
  },
})
  .then(parseResponse);

/**
 *
 * @param {*} search
 * @param {*} query - eg. { conditions: [], operator: 'And/Or' }
 * @param {*} projection - eg. { firstName: 1 }
 * @returns
 */
export const queryList = async ({
  accountId, lId, query, search, sort, requestId, projection, skip, limit, entity, noDefault = false,
}) => API.post(API_NAME, `/${entity}?operation=list`, {
  body: {
    accountId,
    lId,
    query,
    search: (search || ''),
    sort,
    projection: {
      ...(noDefault ? {} : projection)
    },
    skip,
    limit,
    requestId,
  },
})
  .then(parseResponse);

export const getEntityDetails = async ({ _id, entity }) => API.get(API_NAME, `/${entity}?operation=detail&_id=${encodeURIComponent(_id)}`)
  .then(parseResponse);

export const gueryDistinctFields = async ({
  entity, accountId, field, search,
}) => API.post(API_NAME, `/${entity}?operation=getDistinctValues`, {
  body: { accountId, field, search },
})
  .then(parseResponse);

export const exportData = async ({
  accountId, requestId, entity, columnConfig, filter, search, tile, skip = 0,
}) => {
  const params = generateQueryParamsFromFilterV2WithTile(filter, search, tile);
  const projection = generateProjectionExpressionV2(columnConfig);
  return API.post(API_NAME, `/${entity}?operation=export`, {
    body: {
      accountId,
      requestId,
      skip,
      projection,
      ...params,
    },
  })
    .then(parseResponse);
};

export const batchGetEntities = async ({
  accountId, _ids, requestId, projection, entity,
}) => API.post(API_NAME, `/${entity}?operation=batchGet`, {
  body: {
    accountId,
    _ids,
    projection,
    requestId,
  },
})
  .then(parseResponse);


export const exportDataV2 = async ({
  accountId, requestId, entity, columnConfig, filter, search, tile, skip = 0,
}) => {
  const params = generateQueryParamsFromFilterV2WithTile(filter, search, tile);
  const projection = generateProjectionExpressionV2(columnConfig);
  return API.post(API_NAME, `/${entity}?operation=export`, {
    body: {
      accountId,
      requestId,
      skip,
      projection,
      ...params,
    },
  })
    .then(parseResponse);
};

export const queryDeviceActivity = async ({
  accountId, xId, query, search, sort, requestId, projection, skip, limit, noDefault = false,
}) => API.post(API_NAME, `/device?operation=fetchDeviceActivity`, {
  body: {
    accountId,
    query,
    search: (search || ''),
    sort,
    xId,
    projection: {
      ...(noDefault ? {} : projection)
    },
    skip,
    limit,
    requestId,
  },
})
  .then(parseResponse);

export const createProject = async ({ accountId, data }) => API.post(API_NAME, `/device?operation=createProject`, {
  body: {
    accountId, data
  },
})
  .then(parseResponse);

export const updateProject = async ({ accountId, lId, data }) => API.post(API_NAME, `/device?operation=updateProject`, {
  body: {
    accountId, lId, data
  },
})
  .then(parseResponse);

export const fetchReport = async ({ accountId, lId, range }) => API.post(API_NAME, `/device?operation=fetchReport`, {
    body: {
      accountId, lId, range
    },
  })
    .then(parseResponse);