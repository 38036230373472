import emitter from '../Models/Emitter';

export const fireAppEvent = (event, params) => {
  emitter.emit(event, params);
};

export const addEmitterListener = (event, eventFunction) => emitter.addListener(
  event, eventFunction,
);

export const removeEmitterListener = (token) => token.remove();

window.identxlabsEmitter = emitter;
