import React, { PureComponent } from 'react';
import './index.scss';

class DraggableList extends PureComponent {
  handleDragStart(e) {
    this.dragged = e.currentTarget; // store a ref. on the dragged elem
    this.dragged.style.opacity = 0.95;

    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/html', this.dragged); // save dragged Object

    // make a clone and append it to body
    const clone = e.target.cloneNode(true);
    clone.id = 'drag-ghost';
    clone.style.borderTop = '1px solid #1E78F0';
    // clone.style.borderRadius = '2px';
    clone.style.cursor = 'grab';
    clone.style.height = 'inherit';
    e.target.parentNode.appendChild(clone);
    const clientRect = e.target.getBoundingClientRect();
    const offsetX = e.clientX - clientRect.x;
    const offsetY = e.clientY - clientRect.y;
    e.target.ghostDragger = clone;
    e.dataTransfer.setDragImage(clone, offsetX + 60, offsetY); // set the drag feedback image.
  }

  handleDragEnter = (e) => {
    e.target.classList.add('over');
  }

  handleDragOver(e) {
    const clone = document.getElementById('drag-ghost');
    if (clone) clone.parentNode.removeChild(clone);
    e.preventDefault();
    this.over = e.currentTarget;
    e.dataTransfer.dropEffect = 'move';
  }

  handleDragLeave = (event) => {
    event.target.classList.remove('over');
  }

  handleDrop(e) {
    e.stopPropagation();
    if (this.dragged !== this.over) {
      const { updateFieldsOrder, items } = this.props;
      const itemsOrder = items.map((d, index) => index);
      const from = Number(this.dragged.dataset.id);
      const to = Number(this.over.dataset.id);

      const removedItem = itemsOrder.splice(from, 1)[0];
      if (to >= itemsOrder.length) {
        itemsOrder.push(removedItem);
      } else {
        itemsOrder.splice(to, 0, removedItem);
      }
      updateFieldsOrder(itemsOrder);
    }
    e.target.classList.remove('over');
    // this.dragged.style.border = '1px solid white';
  }

  handleDragEnd() {
    this.dragged.style.opacity = 1;
  }

  render() {
    const { items } = this.props;
    return (
      <ul className="ulClass draggable-container borderUl">
        {(items || []).map((item, i) => (
          <li
            data-id={i}
            data-index={i}
            key={i}
            draggable="true"
            onDragStart={this.handleDragStart.bind(this)}
            onDragOver={this.handleDragOver.bind(this)}
            onDragEnter={this.handleDragEnter.bind(this)}
            onDragLeave={this.handleDragLeave.bind(this)}
            onDrop={this.handleDrop.bind(this)}
            onDragEnd={this.handleDragEnd.bind(this)}
            className="draggableList grab"
          >
            {item}
          </li>
        ))}
      </ul>
    );
  }
}

export default DraggableList;
