import { EXEMPTED_STATUS_CODES } from '../constants';
import { onError } from './errorLib';

export const responseCallback = (resp) => {
  if (resp && !EXEMPTED_STATUS_CODES.includes(resp.statusCode)) {
    if (resp?.body) onError(new Error('RESPONSE BROKE:'));
  }
  return resp;
};

export const parseResponse = (resp) => {
  if (resp && !EXEMPTED_STATUS_CODES.includes(resp.statusCode)) {
    throw new Error(resp.errorMessage);
  } else if (!resp) {
    throw new Error('Response not found');
  }
  if (resp?.error || resp?.data?.error || ([400, 404].includes(resp?.statusCode))) {
    throw new Error(resp?.errorMessage || resp?.error || resp?.message || 'Something went wrong, if the same error persist please report at admin@identxlabs.com');
  }
  return resp.result;
};
