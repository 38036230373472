import API from '../libs/amplify.lib';

import { API_NAME } from '../constants';
import { parseResponse, responseCallback } from '../libs/response.lib';
import { generateQueryStringFromObject } from '../utils/query.util';

export const getUser = async (query) => API.get(API_NAME, `/user?${generateQueryStringFromObject(query)}`)
  .then(parseResponse);

export const getAllUsers = async (path) => API.get(API_NAME, `/user/${path}`).then(parseResponse);

export const batchGetUsers = async (payload, query = { operation: 'batchGet' }) => API.post(API_NAME,
  `/user?${generateQueryStringFromObject(query)}`, { body: payload })
  .then(responseCallback);

export const updateUserSettings = async (payload, query = { operation: 'basicField' }) => API.put(API_NAME,
  `/user?${generateQueryStringFromObject(query)}`, { body: payload })
  .then(parseResponse);

export const createUser = async (payload) => API.post(API_NAME, '/user/?operation=addNewUserToAccount', { body: payload }).then(parseResponse);

/** *****               ACCOUNT             ******* */

export const getAccountSettings = async (query) => API.get(API_NAME, `/account?${generateQueryStringFromObject(query)}`)
  .then(parseResponse);

export const updateAccountSettings = async (payload) => API.put(API_NAME, '/account', { body: payload })
  .then(parseResponse);

export const setCognitoId = async (payload) => API.post(API_NAME, '/user', { body: { operation: 'register', data: payload} })
  .then(parseResponse);

export const resetAccountSettings = async (payload) => API.put(API_NAME, '/account',
  { body: { ...payload, operation: 'resetAccountSettings' } })
  .then(parseResponse);

export const deleteUser = async (query) => API.del(API_NAME, `/user?${generateQueryStringFromObject(query)}`)
  .then(parseResponse);

export const deleteUserV2 = async ({
  accountId,
  id,
  userName,
  updatedUserId,
}) => API.post(API_NAME, '/user?operation=removeUser', {
  body: {
    accountId,
    id,
    userName,
    updatedUserId,
  },
}).then(parseResponse);
