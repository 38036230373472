import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { debounce, globalFilterFunction } from '../../utils';
import Label from '../Form/FormLabel';
import MaterialIcon from '../Common/MaterialIcon';
import CloseButton from '../Common/CloseButton';
import { triggerCloseModal } from '../../utils/modal.util';
import PopupHeader from '../Common/PopupHeader';
import SearchBar from '../SearchBar';
import DraggableList from '../Draggable';
import Checkbox from '../Checkbox';

export default function CustomiseTableColumm({
  tableBasicColumns, customFieldTableColumns = [], defaultState, onSubmit = () => { }, mandatoryColumns,
}) {
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');

  useEffect(() => {
    setSelectedColumns(defaultState);
  }, [defaultState]);

  const onSearch = debounce((value) => {
    setGlobalFilter(value);
  }, 500);

  const filteredBasicColumns = useMemo(() => globalFilterFunction({ items: tableBasicColumns, fields: ['label'], globalSearch: globalFilter }), [globalFilter, tableBasicColumns]);
  const filteredCustomColumns = useMemo(() => globalFilterFunction({ items: customFieldTableColumns, fields: ['label'], globalSearch: globalFilter }), [customFieldTableColumns, globalFilter]);

  const onCheckBoxClick = useCallback((option) => {
    const newColumns = [...selectedColumns];
    const alreadyExistIndex = selectedColumns.findIndex((d) => d.value === option.value);
    if (alreadyExistIndex > -1) {
      newColumns.splice(alreadyExistIndex, 1);
    } else newColumns.push(option);
    setSelectedColumns(newColumns);
  }, [selectedColumns]);

  const isColumnChecked = useCallback(({ value }) => !!selectedColumns.find((d) => d.value === value), [selectedColumns]);

  const getColumn = useCallback(({ option, index }) => (
    <div className="row" key={`${option.label}-${index}`}>
      <div className="col-12">
        <div
          role="tab"
          tabIndex={0}
          className="flex align-items-center outline-none"
          onClick={() => onCheckBoxClick(option)}
          onKeyDown={() => onCheckBoxClick(option)}
        >
          <Checkbox
            name={option.label}
            checked={isColumnChecked(option)}
            onCheckBoxClick={() => onCheckBoxClick(option)}
            type="square"
          />
          <span className="text-ellipsis" style={{ maxWidth: 'calc(27vw - 57px)' }}>
            {option.label}
            {' '}
            &nbsp;
          </span>
        </div>
      </div>
    </div>
  ), [isColumnChecked, onCheckBoxClick]);
  const getAllColumnsComponent = useMemo(() => (
    (filteredBasicColumns.length > 0 || filteredCustomColumns.length > 0) ? (
      <>
        <Label text="Common Attributes" />
        {
          filteredBasicColumns.map((option, index) => getColumn({ option, index }))
        }
        <div className="paddingTopTwenty">
          <Label text="Custom Attributes" />
          {
            filteredCustomColumns.map((option, index) => getColumn({ option, index }))
          }
        </div>
      </>
    ) : (
      <div className="marginTopTwelve" style={{ paddingLeft: '22px' }}>
        No Column found
      </div>
    )
  ), [filteredBasicColumns, filteredCustomColumns, getColumn]);

  const onRemoveSelectedColumn = useCallback((index) => {
    const newColumns = [...selectedColumns];
    newColumns.splice(index, 1);
    setSelectedColumns(newColumns);
  }, [selectedColumns]);

  const getSelectedColumnComponent = useMemo(() => (
    selectedColumns.map((el, index) => (
      <div key={`selectedColumn-${index}`} className="show-filters flex align-items-center justify-content-between">
        <div className="content stage">
          <MaterialIcon icon="drag_indicator" id="sixDots" className="noPointerEvents" />
          <div className="text-ellipsis" style={{ maxWidth: 'calc(27vw - 113px)' }}>
            {el.label}
          </div>
        </div>
        <div className="dragToRearrange">
          <div className="fontsm italic">(Drag to rearrange)</div>
          <CloseButton onClick={() => onRemoveSelectedColumn(index)} />
        </div>
      </div>
    ))
  ), [onRemoveSelectedColumn, selectedColumns]);

  const updateFieldsOrder = (newOrder) => {
    const newColumnsOrder = newOrder.map((d) => selectedColumns[d].value);
    const newArr = [...selectedColumns];
    setSelectedColumns(newArr.sort((a, b) => newColumnsOrder.indexOf(a.value) - newColumnsOrder.indexOf(b.value)));
  };

  const getMandtoryColumnComponent = useMemo(() => (
    mandatoryColumns.map((el, index) => (
      <div key={`selectedColumn-${index}`} className="show-filters flex align-items-center justify-content-between mandatoryColumns">
        <div className="content stage">
          <span className="stageLabel visibilityHidden">
            <MaterialIcon icon="drag_indicator" id="sixDots" className="noPointerEvents" />
          </span>
          <div className="text-ellipsis" style={{ maxWidth: '18vw' }}>
            {el.label}
          </div>
        </div>
      </div>
    ))
  ), [mandatoryColumns]);

  const onSaveChanges = () => {
    onSubmit({ selectedCustomisedColumns: selectedColumns });
    triggerCloseModal();
  };
  return (
    <div className="row materialCard popup-container">
      <PopupHeader title="Edit table columns" className="marginBottomTwelve" />
      <div className="col-12 popupBody">
        <div className="row">
          <div className="col-6 noPadding">
            <SearchBar
              placeholder="Search Columns"
              style={{
                marginBottom: '0px',
              }}
              onSearch={(value) => onSearch(value)}
              showEnterImage={false}
            />
          </div>
          <div className="col-6 paddingLeftTwelve flex align-items-center newBlue">
            Selected Columns
          </div>
        </div>
        <div className="row paddingTopTwenty">
          <div className="col-6 allColumnList">{getAllColumnsComponent}</div>
          <div className="col-6 selectedColumnDraggableList">
            {getMandtoryColumnComponent}
            <DraggableList
              items={getSelectedColumnComponent}
              updateFieldsOrder={updateFieldsOrder}
            />
          </div>
        </div>
        <div className="buttonSec">
          <button type="button" className="ctaOpt" onClick={() => triggerCloseModal()}>Cancel</button>
          <button
            type="button"
            className="cta marginLeftEight"
            onClick={onSaveChanges}
          >
            Apply Changes
          </button>
        </div>
      </div>
    </div>
  );
}
