import {
  useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';
import { APP_ROUTES, APP_UNAUTHORIZE_ROUTES, SETTING_ITEM } from '../constants/navigation.constant';

const combinedRoutes = [...APP_UNAUTHORIZE_ROUTES, ...APP_ROUTES];
const getActiveDetails = (pathname) => (pathname.includes('settings') ? SETTING_ITEM : combinedRoutes.find((item) => ((pathname === item.route))));

export const useDocumentTitleChange = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    const details = getActiveDetails(pathname);
    details && (document.title = `IdentXLabs | ${details.name}`);
  }, [pathname]);
};

