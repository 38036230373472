import React from 'react';
import { toast } from 'react-toastify';
import parser from 'html-react-parser';

const options = {
  autoClose: 2500,
  hideProgressBar: true,
  position: 'bottom-left',
  pauseOnHover: true,
  draggable: true,
  closeOnClick: true,
  pauseOnFocusLoss: false,
  icon: false,
};

export const dismissAllToast = () => toast.dismiss();

const ToastContent = ({ head = '', body = '' }) => (
  <div className="toastCont">
    <h3 style={{ marginBottom: body ? '6px' : '0px' }}>{parser(head)}</h3>
    {body && <p>{parser(body)}</p>}
  </div>
);

export const AlertInfo = ({ head, body = '' }, overrideOptions = {}) => {
  toast.info(<ToastContent head={head} body={body} />,
    { ...options, ...overrideOptions });
};

export const AlertError = ({ head, body = '' }, overrideOptions = {}) => {
  toast.error(<ToastContent head={head} body={body} />,
    { ...options, ...overrideOptions });
};

export const AlertWarn = ({ head, body = '' }, overrideOptions = {}) => {
  toast.warn(<ToastContent head={head} body={body} />,
    { ...options, ...overrideOptions });
};

export const AlertSuccess = ({ head, body }, overrideOptions = {}) => {
  toast.success(<ToastContent head={head} body={body} />,
    { ...options, ...overrideOptions });
};

export const CustomAlertToast = ({ body, toastType = 'success' }, overrideOptions = {}) => {
  toast[toastType](body, { ...options, ...overrideOptions });
};

