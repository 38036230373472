import { FETCH_SETTING, FETCH_SETTING_FAILURE, FETCH_SETTING_SUCCESS, UPDATE_PROJECT_SETTING, UPDATE_SETTING } from "../../action/Account";

const initialState = {
    data: {}, isLoading: NaN, error: '',
};
export const settingReducer = (curState = initialState, action = { type: '', payload: '' }) => {
    let newState;
    switch (action.type) {
        case FETCH_SETTING:
            newState = {
                ...curState, error: null, isLoading: true,
            };
            break;
        case FETCH_SETTING_SUCCESS:
            newState = {
                ...curState,
                data: action.payload,
                isLoading: false,
            };
            break;
        case FETCH_SETTING_FAILURE:
            newState = {
                ...curState, data: {}, error: action.payload,
                isLoading: false,
            };
            break;
        case UPDATE_SETTING:
            newState = {
                ...curState, data: { ...curState.data, ...action.payload }
            };
            break;
        case UPDATE_PROJECT_SETTING: {
            const projectIndex = curState.data?.licenseKeys?.findIndex((d) => d.lId === action.payload.lId);
            if (projectIndex !== -1) {
                newState = {
                    ...curState,
                };
                newState.data.licenseKeys = [ ...curState.data.licenseKeys];
                newState.data.licenseKeys[projectIndex] = { ...newState.data.licenseKeys[projectIndex], ...action.payload.data }
            } else {
                newState = curState;
            }
            break;
        }
        default:
            newState = curState;
    }

    return newState;
};
