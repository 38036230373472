import mixpanel from 'mixpanel-browser';
import { isArray, isObject } from '../utils/dataType.util';
import { generateName } from '../utils';

const token = process.env.REACT_APP_MIXPANEL_TOKEN;

const MIXPANEL_EVENTS = {
  'User Verified': 'User Verified',
  'Sign Up': 'Sign Up',
  'Self Signed Up': 'Self Signed Up',
  'Self Signed Up Failed': 'Self Signed Up Failed',
  'Sign In': 'Sign In',
  'Sign Out': 'Sign Out',
  'Sign Up Failed': 'Sign Up Failed',
  'Raise a ticket': 'Raise a ticket',
  'View Help & FAQ': 'View Help & FAQ',
  'Talk To Us': 'Talk To Us',
  'Documentation Visit': 'Documentation Visit',
  'Sync Email': 'Sync Email',
  'Invite Users': 'Invite Users',
  'Email sent': 'Email sent',
  'View Device': 'View Device',
  'Use Device Search': 'Use Device Search',
  'Edit Smart View': 'Edit Smart View',
  'Table Pagination': 'Table Pagination',
  'Create Project': 'Create Project',
  'View Project': 'View Project',
};

Object.freeze(MIXPANEL_EVENTS);

const isTokenDetected = () => token || '';

const initializeMixpanel = () => {
  if (!isTokenDetected()) return;
  mixpanel.init(token, { batch_requests: true, protocol: 'https' });
};

// wait until Mixpanel is loaded
// alternatively you may use the loaded callback in the init() or set_config(): https://github.com/mixpanel/mixpanel-js/blob/8b2e1f7b/src/mixpanel-core.js#L95
function waitUntilLoad(func) {
  if (mixpanel.__loaded) func();
  else {
    setTimeout(() => {
      waitUntilLoad(func);
    }, 10);
  }
}

const registerCommonSettings = (user) => {
  waitUntilLoad(() => {
    if (!isTokenDetected()) return;
    if (isObject(user)) {
      const {
        email, accountId, basicInfo = {}, organisationName,
      } = user;
      mixpanel.identify(email);
      const userName = generateName(basicInfo);
      /* Capture organisation as Company Name in super prop (Industry to be disc) */
      const properties = {
        $email: email, // only reserved properties need the $
        $distinct_id: email, // use human-readable names
        $name: userName,
        'Account Id': accountId,
        'Company Name': organisationName || '',
      };
      mixpanel.people.set(properties);
      mixpanel.register({
        email, accountId, name: userName, 'Company Name': organisationName || '',
      });
    }
  });
};

const onlyIdentifyUser = (id) => {
  waitUntilLoad(() => {
    if (!isTokenDetected()) return;
    id && mixpanel.identify(id);
  });
};

const getSectionFromURL = () => {
  const { pathname } = window.location;
  switch (true) {
    case pathname === '/prospects':
      return 'Prospect Table';
    case pathname === '/accounts':
      return 'Accounts Table';
    case pathname === '/opportunities':
      return 'Opportunities Table';
    case pathname === '/workflows':
      return 'Workflows Table';
    case pathname === '/tasks':
      return 'Tasks Table';
    case pathname === '/templates':
      return 'Templates Table';
    case pathname === '/calls':
      return 'Calls Table';
    case pathname === '/outbox':
      return 'Outbox Table';
    case pathname === '/reports':
      return 'Reports Table';
    case pathname === '/':
      return 'Dashboard';
    case pathname === '/settings/prospects':
      return 'Prospects';
    case pathname === '/settings/accounts':
      return 'Accounts';
    case pathname === '/settings/opportunities':
      return 'Opportunities';
    case pathname === '/templates/switchTemplate':
      return 'Template Creation';
    case pathname.includes('templates/view'):
      return 'Template Page';
    case pathname.includes('/prospects'):
      return 'Prospect Profile';
    case pathname.includes('/accounts'):
      return 'Account Profile';
    case pathname.includes('/opportunities'):
      return 'Opportunity Profile';
    case pathname.includes('/workflows/view'):
      return 'Workflow Profile';
    case pathname === '/meetings':
      return 'Meetings';
    default:
      return pathname;
  }
};


// the actual increment logic
function incrementLogic(property, value) {
  const prop = {};
  prop[property] = 0;
  mixpanel.register_once(prop);
  prop[property] = mixpanel.get_property(property) + value;
  mixpanel.register(prop);
}

// wrapper for the incremental event logic
function increment(property, value) {
  waitUntilLoad(() => {
    incrementLogic(property, value);
  });
}

// increment the user property and setup the event incrementory
function superIncrementLogic(property, amount) {
  increment(property, amount);
  mixpanel.people.increment(property, amount); // comment this line out if you do not wish to add a 'user profile property'
}

// a wrapper which acts a superIncrement method accepting a 'property' and 'amount'
function superIncrement(property, amount) {
  waitUntilLoad(() => {
    superIncrementLogic(property, amount);
  });
}

const logEvent = (event, data = {}, superProperty = {}) => {
  if (!isTokenDetected()) return;
  const pageName = getSectionFromURL();
  if (isArray(superProperty)) {
    superProperty.forEach((d) => {
      const { property, value } = d;
      superIncrement(property, value);
    });
  } else if (isObject(superProperty)) {
    if (superProperty.property) superIncrement(superProperty.property, superProperty.value);
  }
  mixpanel.track(event, { ...data, pageName });
};

export const managePreviewToggle = (callback, showPreview) => {
  callback && callback();
  logEvent(MIXPANEL_EVENTS['Email Preview'], {
    previewStatus: showPreview ? 'Hide' : 'Show',
  });
};

export {
  MIXPANEL_EVENTS, initializeMixpanel, registerCommonSettings, logEvent, onlyIdentifyUser,
  getSectionFromURL, superIncrement,
};
