import React, {
  memo, useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { guid, voidFunction } from '../../utils';
import { isFunction } from '../../utils/dataType.util';
import { enter } from '../../assets/images';
import MaterialIcon from '../Common/MaterialIcon';

function SearchBar({
  onSearch, placeholder, customClass, style = {}, onPressEnter, filterFor, defaultValue, showEnterImage = true,
}) {
  const uniqueId = useRef(guid());
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [textValueOnEnter, setTextValueOnEnter] = useState('');
  const [showEnterButton, setShowEnterButton] = useState(false);

  useEffect(() => {
    const el = document.getElementById(uniqueId.current);
    el.value = defaultValue;
    if (defaultValue?.trim()) {
      setShowCloseButton(true);
      setTextValueOnEnter(defaultValue);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onInputSearch = (e) => {
    const { value } = e.target;
    if (value !== textValueOnEnter) {
      setShowCloseButton(false);
    }
  };
  const onClearSearch = () => {
    const el = document.getElementById(uniqueId.current);
    el.value = '';
    setShowCloseButton(false);
    onPressEnter('');
  };

  const onEnterPress = useCallback((e) => {
    if (isFunction(onPressEnter) && (e.key === 'Enter' || e.keyCode === 13)) {
      const el = document.getElementById(uniqueId.current);
      const { value } = el;
      setTextValueOnEnter(value);
      if (value) {
        setShowCloseButton(true);
      }
      onPressEnter(value);
    }
  }, [onPressEnter]);

  return showEnterImage ? (
    <div className="relativePos onEnterImgInput">
      {showEnterImage && (!showCloseButton ? <img src={enter} alt={`enter ${filterFor}`} className={showEnterButton ? 'visible' : 'visibilityHidden'} />
        : (
          <div
            className="closeTextImage flex align-items-center justify-content-center"
            onClick={onClearSearch}
            onKeyDown={voidFunction}
            tabIndex={0}
            role="button"
          >
            <MaterialIcon
              icon="close"
              className="fontmd"
              style={{
                color: '#1E78F0',
                fontWeight: 300,
              }}
            />
            <span className="appBlue fontmd">Clear</span>
          </div>
        ))}
      <input
        className={`expandableSearch ${customClass}`}
        placeholder={placeholder}
        onChange={onInputSearch}
        onKeyUp={onEnterPress}
        onFocus={() => { showEnterImage && setShowEnterButton(true); }}
        onBlur={() => { showEnterImage && setShowEnterButton(false); }}
        style={style}
        id={uniqueId.current}
      />
    </div>
  ) : (
    <input
      className={`expandableSearch ${customClass}`}
      placeholder={placeholder}
      onChange={(e) => onSearch(e.target.value)}
      onKeyUp={voidFunction}
      onFocus={() => { showEnterImage && setShowEnterButton(true); }}
      onBlur={() => { showEnterImage && setShowEnterButton(false); }}
      style={style}
      id={uniqueId.current}
    />
  );
}

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  customClass: PropTypes.string,
  defaultValue: PropTypes.string,
};

SearchBar.defaultProps = {
  placeholder: 'Search...',
  customClass: '',
  defaultValue: '',
};

export default memo(SearchBar);
