import React from 'react';

const TermsofService = {
  title: 'Terms of Service',
  subtext: 'Updated June 2024',
  content: [
    {
      content:
  <>
    <p>
      <strong>IMPORTANT:</strong>
      {' '}
      BY USING THIS SERVICE, YOU ACKNOWLEDGE AND AGREE THAT YOU BIND YOURSELF TO THE TERMS OF THIS AGREEMENT AND THE PRIVACY POLICY WHICH IS INCORPORATED INTO THIS AGREEMENT
    </p>
  </>,
    },
    {
      buttonText: 'Accepting the terms',
      content:
  <>
    <p>
      This is an agreement between IdentxLabs (“IdentxLabs” or “We”) the owner and operator of the website www.IdentxLabs.ai (the “IdentxLabs site”), the IdentxLabs service and software (collectively, including the services, source code, API libraries, SDK, documentation, web dashboard and applications, henceforth referred to as “IdentxLabs” or “Service”) and you (“User” or “You”), a user of IdentxLabs. In the case of individuals representing organizations, the term User shall include the individual and the organization they represent. “Subscribers” are Users who pay subscription fees to IdentxLabs for use of IdentxLabs. “Apps” or “Applications” refer to application software running on mobile devices (such as apps on iOS or Android or Windows devices) that are created by the User or Users’ organizations. In case IdentxLabs is acquired or is merged with any other entity, all clauses of this agreement will be deemed valid between the User and the new entity or acquiring entity, thereby also transferring all rights held by IdentxLabs to the new entity.
    </p>
    <br />
    <p>
      IF YOU DO NOT AGREE TO ALL THE TERMS OF THIS AGREEMENT, THEN DO NOT CHOOSE THE “LOGIN”, “SIGN UP”, “ACCEPT”, OR “AGREE” BUTTON OR ANY SIMILAR BUTTON OR LINK AND DO NOT USE THE IdentxLabs Service, and IdentxLabs is unwilling to provide you rights to use the product.
    </p>
    <br />
    <p>
      THE SERVICE AND THE SITE ARE AVAILABLE FOR COMPANIES AND INDIVIDUALS AGED 13 OR OLDER. IF YOU ARE 13 OR OLDER BUT UNDER THE AGE OF 18, YOU SHOULD REVIEW THESE TERMS WITH YOUR PARENT OR GUARDIAN TO MAKE SURE THAT YOU AND YOUR PARENT OR GUARDIAN UNDERSTANDS AND ACCEPTS THESE TERMS. BY ACCEPTING THESE TERMS YOU REPRESENT THAT YOU ARE NOT UNDER THE AGE OF 13.
    </p>
    <br />
    <p>
      BY ACCEPTING THE TERMS OF THIS AGREEMENT, YOU ALSO REPRESENT THAT YOU ARE NOT INCAPACITATED OR PROHIBITED BY LAW/COURT ORDERS/STATUTES APPLICABLE TO YOU FROM ENTERING INTO AN AGREEMENT OF THIS NATURE OR FROM USING THESE SERVICES.
    </p>
    <br />
    <p>
      WRITTEN APPROVAL IS NOT A PREREQUISITE TO THE VALIDITY OR ENFORCEABILITY OF THIS AGREEMENT AND NO SOLICITATION OF OR ANY SUCH WRITTEN APPROVAL BY OR ON BEHALF OF IdentxLabs SHALL BE CONSTRUED AS AN INFERENCE TO THE CONTRARY. IF THESE TERMS ARE CONSIDERED AN OFFER BY IdentxLabs, USER’S ACCEPTANCE IS EXPRESSLY LIMITED TO THESE TERMS.
    </p>
    <br />
    <h5>Obtaining Access to IdentxLabs:</h5>
    <br />
    <p>
      You may obtain access to IdentxLabs only via our website
      {' '}
      <a href="https://www.IdentxLabs.ai">www.IdentxLabs.ai</a>
      . You shall not download the IdentxLabs API library files, documentation, or source files from any other sites except where distributed explicitly by IdentxLabs. Your use of IdentxLabs is illegal if you have obtained access via an individual or entity where IdentxLabs has not explicitly permitted sale, download or distribution of the product.
    </p>
    <br />
    <h5>Terms of obtaining Access to IdentxLabs:</h5>
    <p>
      <ol>
        <li>
          The IdentxLabs site and service allow Users (who could potentially be application developers and businesses or individuals providing Apps to others) to identify and implement sales and growth hacking techniques (this is different from IdentxLabs Users - this refers to users of services or software provided by IdentxLabs Users).
        </li>
        <li>
          IdentxLabs grants you a limited, non-transferable, non-assignable license to use IdentxLabs for your Apps. IdentxLabs reserves all other rights related to the service that has not been expressly licensed to you. You hereby acknowledge that no right, title, interest in the nature of or ownership in IdentxLabs being transferred or assigned, except the license as aforesaid, and this AGREEMENT is not to be construed as a sale of any rights in IdentxLabs.
        </li>
        <li>
          You represent that you shall not directly or indirectly, aid or attempt to (i) crack, hack, reverse-engineer, decompile, disassemble, decrypt, duplicate, copy, replicate the software or content/files associated with it, attempt to derive source code or structure, (ii) modify, derive from the software, (iii) remove copyright notices or other notices or logos, (iv) use design or copy or images from the service without explicit written permission from IdentxLabs, (v) use the service or its components for any purpose other than for intended use, (vi) violate other users’ privacy, (vii) circumvent any security measures, (viii) impersonate others or use the service for harassing or any illegal activities (ix) damage your own or other users’ services in any manner, (x) gain illegal or unauthorized access to content, software, services, networks or systems or (xi) disrupt or damage computer systems, networks, software or data associated with IdentxLabs.
        </li>
        <li>
          You represent and agree that you shall not to use any automated systems or tools that may lead to using the service in a manner that generates more events or requests than could be generated by a human in the same time
        </li>
        <li>
          You are free to advertise this software to others through legal channels (sharing information on the service or link to the site via blogs, websites, twitter, etc.).
        </li>
        <li>
          IdentxLabs may modify, suspend, or terminate the service offered at any point in time at its sole discretion. IdentxLabs may also impose restrictions on usage or usage limits on certain features at its sole discretion.
        </li>
        <li>
          You unconditionally acknowledge and agree that IdentxLabs is the owner of the software/product, and all copyrights and all other rights in the nature of copyright and other common law rights solely and exclusively vest in IdentxLabs, and you shall not infringe the same in any manner, directly or indirectly, and shall not cause to be infringed by third parties by any of your acts. You shall not, at any point of time, challenge or dispute or deny the right, title and interest of IdentxLabs and the copyright and all other rights in the nature of copyright and all common law rights of IdentxLabs in respect of the software/product.
        </li>
        <li>
          You shall not re-distribute or re-sell the IdentxLabs service and access to it without explicit written permissions and a formal arrangement with IdentxLabs todo so.
        </li>
        <li>
          You are permitted to modify source code and resources made available for the purpose of modifying the user interfaces and experience of the IdentxLabs service for your Apps.
        </li>
        <li>
          You shall not use the Service for any purposes that may be illegal or may violate the privacy of other Users and your App users or other Users’ App users.
        </li>
      </ol>
    </p>
  </>,
    },
    {
      buttonText: 'Changes to terms',
      content:
  <>
    <ol>
      <li>
        IdentxLabs may, at its sole discretion, enhance, alter or modify the IdentxLabs Service at any point of time as it desires. IdentxLabs shall distribute updates via the Site and may or may not notify the User of such updates. Updates to the product are also considered part of IdentxLabs or Service.
      </li>
      <li>
        In some cases, IdentxLabs shall require the user to upgrade to a newer version of IdentxLabs to continue using the service for security or other reasons.
      </li>
      <li>
        IdentxLabs reserves the right to update or modify this AGREEMENT at its sole discretion. Updates to the agreement shall be made available via the IdentxLabs Site (IdentxLabs.co/terms) and Users with valid email addresses registered with the Service may be intimated of the changes via email. By continuing to use the product post updates posted on the IdentxLabs site, the user is bound by the new agreement. If the agreement is not acceptable to the user, the user shall have to stop using the Service. It is the responsibility of the user to check the site periodically for updates to the agreement.
      </li>
    </ol>
    <h5>Termination of Service and License</h5>
    <br />
    <ol>
      <li>
        IdentxLabs may decide at its sole discretion to terminate the services provided as part of the IdentxLabs Service at any time with a notifying email sent to users registering with valid email ids 10 days in advance of such termination.
      </li>
      <li>
        In the case of termination of the service, all user-created data of campaigns could be non-accessible post-termination. IdentxLabs has no obligations to provide a mechanism for users to access user content created or shared using IdentxLabs post such termination.
      </li>
      <li>
        IdentxLabs can change the terms of this license at any time and revoke the license to use the product from individuals at its sole discretion.
      </li>
    </ol>
  </>,
    },
    {
      buttonText: 'Payment Terms',
      content:
  <>
    <h5>Fees and Payment</h5>
    <br />
    <p>
      Subscribers are required to pay IdentxLabs fees for the Service as laid out in the Pricing section of the IdentxLabs. Unless otherwise agreed to by the parties in writing, all Fees are payable by credit card. The Fees is payable within 5 days from the end of the subscription period for each period. Subscriber hereby authorizes IdentxLabs to charge Subscriber's credit card for purposes of recurring payments towards the fees. Non-payment of fees shall lead to termination of access to features of the Service or termination of the User account by IdentxLabs. Any service taxes payable shall be borne by the Subscriber. In case of any disputes on the billing amount charged, the Subscriber shall have to notify IdentxLabs within 60 days of the amount being charged to be considered for correction. Subscriber fees are non-refundable except if the Subscriber terminates this agreement for breach by IdentxLabs, or IdentxLabs terminates the agreement.
    </p>
    <br />
    <h5>Warranty</h5>
    <br />
    <p>
      You agree that you are aware and fully acknowledge that this Service has no warranty of any kind. IdentxLabs shall not be responsible for costs, expenses, damages of any kind incurred due to or arising from usage or failure of the Service.
    </p>
  </>,
    },
    {
      buttonText: 'Current Policy',
      content:
  <>
    <h5>Privacy</h5>
    <br />
    <p>
      Customer Data provided to IdentxLabs will be subject to IdentxLabs’s privacy policy (See IdentxLabs’s Privacy Policy
      {' '}
      <a href="https://IdentxLabs.ai/privacy-policy">here</a>
      {' '}
      ).
    </p>
    <br />
    <p>
      With respect to any personally identifiable information (“PII”) that you provide to IdentxLabs, you represent and warrant that: (i) you will comply with all applicable laws relating to the collection, use and disclosure of PII; (ii) you have posted (or you will post) a privacy policy on each website on which you use the Service, which clearly and conspicuously discloses that: (a) you use third-party service providers to provide certain services to you in connection with your operation of such website, including the collection and tracking of certain data and information regarding your product or service; and (b) you may disclose PII to certain such third-party services providers for the sole purposes of provision of services by the third-party service providers to you; and (iii) you have made all required notifications and obtained all required consents and authorizations from your website visitors relating to the disclosure of PII to a third-party service provider like IdentxLabs. You further acknowledge and agree that your indemnity obligation under the “Indemnity” paragraph below applies to any third-party claims relating to your disclosure of any PII to IdentxLabs.
    </p>
    <br />
    <h5>
      Use of Customer Data
    </h5>
    <br />
    <p>
      You have the exclusive right to use all Customer Data and all reports based on Customer Data generated by the Service, for any purpose related to your business. Notwithstanding the foregoing, you acknowledge and agree that IdentxLabs may, at any time and in its sole discretion: (i) use any or all Customer Data internally as needed to troubleshoot issues or improve the Service; and (ii) use, for its internal purposes, statistics collected about how Customer Data is used with the IdentxLabs Service.
    </p>
    <br />
    <h5>Indemnity</h5>
    <br />
    <p>You agree to indemnify IdentxLabs, its directors, employees, and partners, harmless from and against any claims, actions or demands, liabilities and settlements including without limitation, reasonable legal and accounting fees, resulting from, or alleged to result from, your violation of this Agreement, your access to the Service, or use or misuse of the Service.</p>
  </>,
    },
    {
      buttonText: 'General Legal Terms',
      content:
  <>
    <h5>Jurisdiction</h5>
    <br />
    <p>
      You agree that jurisdiction for settling any disputes with IdentxLabs lies with courts of Delaware, United States.
    </p>
    <br />
    <h5>Disclaimers</h5>
    <br />
    <p>
      THE IdentxLabs SERVICE AND ANY IdentxLabs CLIENT APPLICATION ARE PROVIDED “AS IS”, WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED. WITHOUT LIMITING THE FOREGOING, IdentxLabs DISCLAIMS ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR AND NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. IdentxLabs MAKES NO WARRANTY THAT THE IdentxLabs CLIENT APPLICATION OR THE SERVICE WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM IdentxLabs OR THROUGH THE SITE WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.
    </p>
    <br />
    <h5>Proprietary Rights Notices</h5>
    <br />
    <p>
      All trademarks, service marks, logos, trade names and any other proprietary designations of IdentxLabs used herein are trademarks or registered trademarks of IdentxLabs. Any other trademarks, service marks, logos, trade names and any other proprietary designations are the trademarks or registered trademarks of their respective parties.
    </p>
    <br />
    <h5>Assignment</h5>
    <br />
    <p>
      You may not assign or transfer this Agreement, by operation of law or otherwise, without IdentxLabs’s prior written consent. Any attempt by you to assign or transfer this Agreement, without such consent, will be null and of no effect. IdentxLabs may assign or transfer this Agreement, at its sole discretion, without restriction. Subject to the foregoing, this Agreement will bind and inure to the benefit of the parties, their successors and permitted assigns.
    </p>
    <br />
    <h5>Notices</h5>
    <br />
    <p>
      Any notices or other communications permitted or required hereunder, including those regarding modifications to this Agreement, will be in writing and given: (i) by IdentxLabs via email (in each case to the address that you provide) or (ii) by posting to the Site. For notices made by e-mail, the date of receipt will be deemed the date on which such notice is transmitted.
    </p>
    <br />
  </>,
    },
    {
      buttonText: 'Complaints & Grievances',
      content:
  <>
    <p>
      For any complaints or grievances related to IdentxLabs, please reach us at
      {' '}
      <a href="mailto:contact@IdentxLabs.ai">contact@IdentxLabs.ai</a>
      .
    </p>
  </>,
    },
  ],
};

const PrivacyPolicy = {
  title: 'Privacy Policy',
  subtext: 'Updated June 2024',
  content: [
    {
      content:
  <>
    <p>
      This Privacy Policy has been compiled to establish how ‘Personally Identifiable Information’ (PII) is being used at IdentxLabs. PII, as described in US privacy law and information security, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. This privacy policy provides a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website.
    </p>
  </>,
    },
    {
      buttonText: 'Data Collection Terms',
      content:
  <>
    <h5>What information do we collect from the people that visit our website, blog, or application?</h5>
    <br />
    <p>
      We collect certain personal information from prospects/customers/users when they sign up to use our services. This information can be used to identify you, such as your name, e-mail address, phone number, company name and any other relevant information voluntarily given to us for providing with our service. The information we collect, to the extent that it is private, is disclosed only in accordance with our Terms of Service and/or this Privacy Policy.
    </p>
    <br />
    <p>
      As you navigate the Service, we may also collect information using commonly used information-gathering tools, such as cookies (“Web Site Navigational Information”). Web Site Navigational Information includes standard information from your web browser (such as browser type and browser language), your Internet Protocol (“IP”) address, and the actions you take on the Service (such as the web pages viewed and the links clicked).
    </p>
    <br />
    <p>
      Through our import process, we may also collect information provided by Customers, such as the name, company name, email address, phone number etc. of Customer End Users that you choose to share with us. When you provide us with personal information about your Customer End Users, we will only use this information for the specific reason for which it is provided, such as to add new records to your account, or as agreed in the applicable agreement with Customer.
    </p>
    <br />
    <p>
      We collect certain payment and billing information when you register for our paid Services. For example, we ask you to designate a billing representative, including name and contact information, upon registration. You might also provide payment information, such as payment card details, which we collect via secure payment processing services like Stripe.
    </p>
    <br />
    <p>
      IdentxLabs employees are strictly prohibited from viewing the content of the data you import into your IdentxLabs account, except as provided in this Privacy Policy. Of course, in the case that you request our help to resolve your support issues relating to that data, and only after receiving your permission, IdentxLabs employees may access the relevant data.
    </p>
    <br />
    <h5>Information collected from website visits:</h5>
    <br />
    <p>
      Whenever you visit our website, we may collect non-identifying information from you, such as your referring URL, browser, operating system, cookie information, and Internet Service Provider.
    </p>
    <br />
    <p>
      We use cookies on this website. We may send one or more cookies which may be stored by your browser on your computer. The information we obtain from cookies is part of the collected information.
    </p>
    <br />
    <p>
      Most browsers allow you to refuse to accept cookies. To improve our services and this site, We may retain third party service providers to operate this site and help us monitor, collect and analyze information regarding your interactions with this website and data you input, including through the use of such providers’ cookies on your computer.
    </p>
  </>,
    },
    {
      buttonText: 'Data Usage Terms',
      content:
  <>
    <h5>How do we use your information?</h5>
    <br />
    <p>
      Collected Information, including personal data submitted on this website (i.e., data that can be used to identify, contact or locate an individual) will be used for the purposes specified in this privacy policy or in relevant parts of the website.
    </p>
    <br />
    <h5>We may use all collected information to:</h5>
    <ol>
      <li>Administer and improve this website or product usability;</li>
      <li>
        We use your registered email address to send information about the product updates and related articles; If you no longer wish to receive communications from us that are not required for the Service, please follow the "unsubscribe" instructions provided in any of those communications or update your account settings information.
      </li>
    </ol>
    <p>
      When you use IdentxLabs, you have the option to connect a 3rd party email account (like Gmail, Outlook, etc) to your IdentxLabs account. This allows you to read, compose and send email messages in IdentxLabs.
    </p>
    <br />
    <p>
      For Gmail users, IdentxLabs needs permissions to some restricted scopes and the usage of each are explained below
    </p>
    <br />
    <h5>gmail.readonly permission:</h5>
    <br />
    <p>
      gmail.readonly permission will be used to read the email replies to change the prospect status in IdentxLabs/CRM’s like Salesforce and by improving the sales development representative productivity.
    </p>
    <br />
    <h5>gmail.send permission:</h5>
    <br />
    <p>
      gmail.send permission will be used to send emails to prospects in a rhythmic sequence to improve SDR’s productivity.
    </p>
    <br />
    <h5>calendar.events permission:</h5>
    <br />
    <p>
      calendar.events permission will be used to read the events to display availability and create event when a prospect books a meeting.
    </p>
    <br />
    <h5>calendar.readonly permission:</h5>
    <br />
    <p>
      calendar.settings.readonly permission used to read user time zone and working hours and other settings.
    </p>
    <br />
    <p>
      <strong>NOTE:</strong>
      {' '}
      All the data collected through the Google OAuth restricted scopes will not be shared to any third parties or will not be used to personalize advertisements.
    </p>
    <br />
    <h5>Other disclosures</h5>
    <br />
    <p>
      In addition to the disclosures reasonably necessary for the purposes identified elsewhere in this privacy policy, we may disclose information about you:
    </p>
    <ol>
      <li>To the extent that we are required to do so by law;</li>
      <li>In connection with any legal proceedings or prospective legal proceedings;</li>
      <li>In response to lawful requests by public authorities, including to meet national security or law enforcement requirements.</li>
    </ol>
  </>,
    },
    {
      buttonText: 'Data Protection Regulations',
      content:
  <>
    <h5>How do we protect your information?</h5>
    <br />
    <p>
      We are a privacy-led design company and we take our customers personal information privacy and security very seriously. Accordingly, we will implement reasonable and appropriate security measures to protect your personal information from loss, misuse and unauthorized access, disclosure, alteration, and destruction.
    </p>
    <br />
    <p>
      We will store all the personal information you provide on our secure servers.
    </p>
    <br />
    <p>
      You are responsible for keeping your passwords confidential. We will not ask you for your passwords.
    </p>
    <br />
    <h5>Policy amendments</h5>
    <br />
    <p>
      This Privacy Policy may be updated from time to time. Rest assured, we will notify you of any material changes which you can review as the updated Privacy Policy posted on the IdentxLabs website. If you do not accept the updated Privacy Policy, you may terminate Service as provided in the IdentxLabs Service Agreement; otherwise the updated Privacy Policy will apply to you.
    </p>
    <br />
    <h5>Third party websites</h5>
    <br />
    <p>
      The website contains links to other websites. We are not responsible for the privacy policies of third-party websites or such site operators’ actions including the collection or use of your personal information.
    </p>
    <br />
    <h5>Protection Policy</h5>
    <br />
    <p>
      IdentxLabs complies with the EU-U.S and the Swiss-U.S. Privacy Shield Frameworks as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal information transferred from the European Union and Switzerland to the United States, respectively. IdentxLabs adheres to the Privacy Shield Privacy Principles of notice, choice, onward transfer, security, data integrity, access, and enforcement.
    </p>
    <br />
    <p>
      With respect to personal data received or transferred pursuant to the Privacy Shield Framework, IdentxLabs is subject to the regulatory enforcement powers of the U.S. Federal Trade Commission. In certain situations, IdentxLabs may be required to disclose personal data in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.
    </p>
    <br />
    <h5>Changing or Deleting Your Information</h5>
    <br />
    <p>
      Upon request IdentxLabs will provide you with information about whether we hold, or process on behalf of a third-party, any of your personal information. If you are a registered user, you may review, update, correct, or delete certain Personal Information provided in your registration or account profile by changing your account settings. If your personal information changes, or if you no longer desire our Service, you may update or delete it by making the change in your account settings, although (in some cases) we may retain copies of your information if required by law, to protect our rights, or if it is not technically reasonably feasible to remove it. For questions about your Personal Information on our Service, please contact us at contact[at]IdentxLabs.ai or by contacting us through our website. We will respond to your inquiry within 30 days.
    </p>
    <br />
    <h5>How does our site handle Do Not Track signals?</h5>
    <br />
    <p>
      We honor Do Not Track signals and Do Not Track, plant cookies, or use advertising when a Do Not Track (DNT) browser mechanism is in place.
    </p>
    <br />
    <h5>Accountability for Onward Transfers</h5>
    <br />
    <p>We do not sell your Personal Information to any third party.</p>
    <br />
    <p>IdentxLabs does not transfer any kind of user data (no matter what) to third parties.</p>
    <br />
    <h5>Phishing</h5>
    <br />
    <p>
      IdentxLabs does not treat ‘phishing’ or identity theft lightly. Safeguarding information to help protect you from identity theft is a top priority. We do not and will not, at any time, request your credit card information, your account ID, login password, or national identification numbers in a non-secure or unsolicited e-mail or telephone communication.
    </p>
    <br />
    <h5>Business Transfers</h5>
    <br />
    <p>
      In the case of a merger, acquisition or reorganization, IdentxLabs may sell, transfer or otherwise share some or all of its assets, including your customer data, in order to provide you continued service , but we will notify you. We will also notify you of choices you may have regarding the information.
    </p>
    <br />
    <h5>Google</h5>
    <br />
    <p>
      Google’s advertising requirements can be summed up by Google’s Advertising Principles. They are put in place to provide a positive experience for users
    </p>
    <br />
    <h5>We use Google AdSense Advertising on our website</h5>
    <br />
    <p>
      Google, as a third-party vendor, uses cookies to serve ads on our site. Google’s use of the DART cookie enables it to serve ads to our users based on previous visits to our site and other sites on the Internet. Users may opt-out of the use of the DART cookie by visiting the Google Ad and Content Network privacy policy.
    </p>
    <br />
    <h5>We have implemented the following:</h5>
    <br />
    <h5 className="mx-2">Remarketing with Google AdSense</h5>
    <br />
    <p className="mx-2">
      We, along with third-party vendors such as Google use first-party cookies (such as the Google Analytics cookies) and third-party cookies (such as the DoubleClick cookie) or other third-party identifiers together to compile data regarding user interactions with ad impressions and other ad service functions as they relate to our website.
    </p>
    <br />
    <h5 className="mx-2">Opting out:</h5>
    <br />
    <p className="mx-2">
      Users can set preferences for how Google advertises to you using the Google Ad Settings page. Alternatively, you can opt out by visiting the Network Advertising Initiative Opt Out page or by using the Google Analytics Opt Out Browser add-on
    </p>
    <br />
  </>,
    },
    {
      buttonText: 'Policies Opted',
      content:
  <>
    <h5>California Online Privacy Protection Act</h5>
    <br />
    <p>
      CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law’s reach stretches well beyond California to require any person or company in the United States (and conceivably the world) that operates websites collecting Personally Identifiable Information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals or companies with whom it is being shared. See more
    </p>
    <br />
    <h5>According to CalOPPA, we agree to the following:</h5>
    <ol>
      <li>Users can visit our site anonymously.</li>
      <li>
        Once this privacy policy is created, we will add a link to it on our homepage or at a minimum, on the first significant page after entering our website.
      </li>
      <li>
        Our Privacy Policy link includes the word ‘Privacy’ and can easily be found on the page specified above.
      </li>
      <li>
        You will be notified of any Privacy Policy changes.
      </li>
      <li>
        On our Privacy Policy Page you can change your personal information by emailing us.
      </li>
    </ol>
    <h5>COPPA (Children Online Privacy Protection Act)</h5>
    <br />
    <p>
      When it comes to the collection of personal information from children under the age of 13 years old, the Children’s Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, United States’ consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and online services must do to protect children’s privacy and safety online.
    </p>
    <br />
    <p>
      We do not specifically market to children under the age of 13 years old.
    </p>
    <br />
    <h5>Fair Information Practices</h5>
    <br />
    <p>
      The Fair Information Practices Principles form the backbone of privacy law in the United States and the concepts they include have played a significant role in the development of data protection laws around the globe. Understanding the Fair Information Practice Principles and how they should be implemented is critical to comply with the various privacy laws that protect personal information.
    </p>
    <br />
    <h5>CAN SPAM Act</h5>
    <br />
    <p>
      The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells out tough penalties for violations. IdentxLabs service compiles with CAN SPAM Act.
    </p>
    <br />
    <h5>We collect your email address in order to:</h5>
    <ol>
      <li>
        Send information, respond to inquiries, and/or other requests or questions. We will only use personal information for the purpose for which it was collected.
      </li>
      <li>
        We will take reasonable steps to ensure that personal information is relevant to its intended use, complete, and current.
      </li>
    </ol>
    <h5>To be in accordance with CANSPAM, we agree to the following:</h5>
    <ol>
      <li>Not use false or misleading subjects or email addresses.</li>
      <li>Identify the message as an advertisement in some reasonable way.</li>
      <li>Include the physical address of our business or site headquarters.</li>
      <li>Monitor third-party email marketing services for compliance, if one is used.</li>
      <li>Honor opt-out/unsubscribe requests quickly.</li>
      <li>Allow users to unsubscribe by using the link at the bottom of each email.</li>
    </ol>
    <h5>Enforcement and Liability</h5>
    <br />
    <p>
      IdentxLabs will conduct compliance audits of its privacy practices to verify adherence to this Privacy Policy. IdentxLabs employees that are found to have violated this Privacy Policy will be subjected to disciplinary action up to and including termination of their employment with IdentxLabs.
    </p>
    <br />
    <h5>Vulnerability Disclosure Program</h5>
    <br />
    <p>
      If you come across any bugs/issues, please feel free to report them to us on
      {' '}
      <a href="mailto:contact@IdentxLabs.ai"> contact@IdentxLabs.ai</a>
      .
    </p>
    <br />
  </>,
    },
    {
      buttonText: 'Contact',
      content:
  <>
    <p>
      If you have any questions about this privacy policy or our treatment of your personal data, please write to us by email to
      {' '}
      <a href="mailto:contact@IdentxLabs.ai">contact@IdentxLabs.ai</a>
      .
    </p>
    <br />
    <p>
      In compliance with the EU-U.S. and Swiss-U.S. Privacy Shield Principles, IdentxLabs commits to resolve complaints about your privacy and our collection or use of your Personal Information. EU or Swiss individuals with inquiries or complaints regarding this privacy policy should first contact IdentxLabs at:
      {' '}
      <a href="mailto:contact@IdentxLabs.ai">contact@IdentxLabs.ai</a>
      .
    </p>
  </>,
    },
  ],
};

export { TermsofService, PrivacyPolicy };
