import { SPINNER_COUNTER } from "../action/type";

const initialState = 0;

export const spinnerCounterReducer = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState = curState;
  const { type } = action;
  if (type === SPINNER_COUNTER) {
    newState += curState + 1;
  }
  return newState;
};

