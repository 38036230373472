import { SUPER_ADMIN_EMAIL } from '../constants';

export const removeAllExistingPoups = () => {
  const modalElements = document.getElementsByClassName('ReactModalPortal');
  for (let index = modalElements.length - 1; index >= 0; index -= 1) {
    modalElements[index].parentNode.removeChild(modalElements[index]);
  }
};

export const isSuperAdmin = (email) => email === SUPER_ADMIN_EMAIL;
