import {
    sub, startOfDay, endOfDay,
    startOfWeek,
    endOfWeek,
    addDays,
    startOfMonth,
    addMonths,
    endOfMonth,
    startOfYear,
    endOfYear,
    format,
  } from 'date-fns';
import { DATE_FILTER_RANGE_MAP } from '../constants/filter.constant';

export const INFINITE_DATE = 10000002599999;

const getClientTimezone = () => Intl.DateTimeFormat().resolvedOptions()?.timeZone;
export const CLIENT_TIMEZONE = (getClientTimezone() === 'Asia/Calcutta' ? 'Asia/Kolkata' : getClientTimezone()) || 'Asia/Kolkata';

  
  export const generateDefaultDateRange = (days = 7) => ({
    startDate: startOfDay(sub(Date.now(), { days })).getTime(),
    endDate: endOfDay(Date.now()).getTime(),
  });

  export const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfTomorrow: startOfDay(addDays(new Date(), 1)),
    endOfTomorrow: endOfDay(addDays(new Date(), 1)),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfLastFourMonths: startOfMonth(addMonths(new Date(), -4)),
    startOfLastThreeMonths: startOfMonth(addMonths(new Date(), -3)),
    startOfLastSixMonths: startOfMonth(addMonths(new Date(), -6)),
    startOfPreviousYear: startOfYear(addMonths(new Date(), -12)),
    endOfPreviousYear: endOfYear(addMonths(new Date(), -12)),
    startOfCurrentYear: startOfYear(new Date()),
    endOfCurrentYear: endOfYear(new Date()),
  };
  
export const universalDateFormat = (d) => format(d || Date.now(), 'd LLL yy h:mm a');

export function getStartAndEndDateForInsights(option) {
  let startDate = defineds.startOfToday; let endDate = defineds.startOfToday;
  switch (option) {
    case DATE_FILTER_RANGE_MAP.Yesterday: {
      startDate = defineds.startOfYesterday;
      endDate = defineds.endOfYesterday;
      break;
    }
    case DATE_FILTER_RANGE_MAP.Today: {
      startDate = defineds.startOfToday;
      endDate = defineds.endOfToday;
      break;
    }
    case DATE_FILTER_RANGE_MAP['This Week']: {
      startDate = defineds.startOfWeek;
      endDate = defineds.endOfWeek;
      break;
    }
    case DATE_FILTER_RANGE_MAP['The Last Week']: {
      startDate = defineds.startOfLastWeek;
      endDate = defineds.endOfLastWeek;
      break;
    }
    case DATE_FILTER_RANGE_MAP['This Month']: {
      startDate = defineds.startOfMonth;
      endDate = defineds.endOfMonth;
      break;
    }
    case DATE_FILTER_RANGE_MAP['The Last Month']: {
      startDate = defineds.startOfLastMonth;
      endDate = defineds.endOfLastMonth;
      break;
    }
    case DATE_FILTER_RANGE_MAP['This Year']: {
      startDate = defineds.startOfCurrentYear;
      endDate = defineds.endOfCurrentYear;
      break;
    }
    case DATE_FILTER_RANGE_MAP['The Last Year']: {
      startDate = defineds.startOfPreviousYear;
      endDate = defineds.endOfPreviousYear;
      break;
    }
    case DATE_FILTER_RANGE_MAP['One Month Ago']: {
      startDate = defineds.startOfLastMonth;
      endDate = defineds.endOfLastMonth;
      break;
    }
    case DATE_FILTER_RANGE_MAP['One Week Ago']: {
      startDate = defineds.startOfLastWeek;
      endDate = defineds.endOfLastWeek;
      break;
    }
    case DATE_FILTER_RANGE_MAP['One Year Ago']: {
      startDate = defineds.startOfPreviousYear;
      endDate = defineds.endOfPreviousYear;
      break;
    }
    default:
      break;
  }
  return { startDate: startOfDay(startDate).getTime(), endDate: endOfDay(endDate).getTime() };
}