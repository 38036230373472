import React, {
  memo, useEffect, Suspense, lazy, useCallback,
} from 'react';
import {
  Route, Switch, useHistory,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Chart from 'chart.js/auto';
import { ToastContainer } from 'react-toastify';
import OrientationScreen from './components/OrientationScreen';
import ErrorBoundary from './components/ErrorBoundary';
import { hasActiveSession } from './services/Authentication';
import { initializeMixpanel } from './libs/mixpanel';
import FullScreenSpinner from './components/Spinner';
import emitter from './Models/Emitter';
import { CustomHistory } from './Models/History';
import { lazyComponentLoader } from './utils/modal.util';
import { resetStore, setUserAuthenticated } from './redux/action/creator';
import { useDocumentTitleChange } from './CustomHooks/useDocumentTitleChange';
// import { hideHelpCrunch, loadHelpcrunchScript } from './libs/helpcrunch';
import './App.scss';

const AuthenticatedRoute = lazy(() => lazyComponentLoader(() => import('./containers/AuthenticatedRoute')));
const Login = lazy(() => lazyComponentLoader(() => import('./containers/Login')));

initializeMixpanel();

// loadHelpcrunchScript();

function App() {
  const navigate = useHistory();
  useDocumentTitleChange();

  useEffect(() => () => {
    emitter.removeAllListeners();
  }, []);

  const dispatch = useDispatch();

  const onActiveSession = useCallback(
    () => {
      dispatch(setUserAuthenticated());
    },
    [dispatch],
  );

  const onInActiveSession = useCallback(
    () => {
      dispatch(resetStore());
    },
    [dispatch],
  );

  useEffect(() => {
    CustomHistory.setHistory(navigate);
    // hideHelpCrunch();
    async function onLoad() {
      return hasActiveSession(onActiveSession, onInActiveSession);
    }
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App-container">
      <ToastContainer />
      <ErrorBoundary>
        <Suspense fallback={<FullScreenSpinner text="Loading..." />}>
          <Switch>
            <Route exact path="/confirmCognitoUser" render={() => <Login userConfirmation />} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/resetPassword" render={() => <Login showResetPasswordPage />} />
            <Route path="/" component={AuthenticatedRoute} />
          </Switch>
        </Suspense>
        <OrientationScreen />
      </ErrorBoundary>
    </div>
  );
}

export default memo(App);
