import {
  FETCH_DEVICE_DETAIL,
  FETCH_DEVICE_DETAIL_FAILURE,
  FETCH_DEVICE_DETAIL_SUCCESS,
  UPDATE_DEVICE_DETAIL,
  RESET_DEVICE_DATAMAP,
  REMOVE_DEVICE_DETAIL,
} from '../../action/Device';

const initialState = {
  detailMap: { },
};
const DeviceDetailsReducer = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  switch (action.type) {
    case FETCH_DEVICE_DETAIL:
      newState = {
        ...curState,
        detailMap: {
          ...curState.detailMap,
          [action.payload._id]: {
            isLoading: true,
            data: {},
            error: '',
          },
        },
      };
      break;
    case FETCH_DEVICE_DETAIL_SUCCESS: {
      if (curState.detailMap[action.payload._id]) {
        newState = {
          ...curState,
          detailMap: {
            ...curState.detailMap,
            [action.payload._id]: {
              isLoading: false,
              data: action.payload,
              error: '',
              lastFetched: Date.now(),
            },
          },
        };
      } else {
        newState = curState;
      }
      break;
    }
    case FETCH_DEVICE_DETAIL_FAILURE: {
      if (curState.detailMap[action.payload._id]) {
        newState = {
          ...curState,
          detailMap: {
            ...curState.detailMap,
            [action.payload.id]: {
              ...curState.detailMap[action.payload._id],
              isLoading: false,
              error: action.payload.error,
              lastFetched: Date.now(),
            },
          },
        };
      } else {
        newState = curState;
      }

      break;
    }
    case UPDATE_DEVICE_DETAIL: {
      const { _id, data } = action.payload;
      if (curState.detailMap[_id]) {
        newState = {
          ...curState,
          detailMap: {
            ...curState.detailMap,
            [_id]: {
              ...curState.detailMap[_id],
              data: {
                ...curState.detailMap[_id].data,
                ...data,
              },
              lastFetched: Date.now(),
            },
          },
        };
      } else {
        newState = curState;
      }
      break;
    }
    case RESET_DEVICE_DATAMAP: {
      newState = { ...initialState };
      break;
    }
    case REMOVE_DEVICE_DETAIL: {
      const { detailMap } = curState;
      if (detailMap[action.payload]) {
        delete detailMap[action.payload];
        newState = {
          detailMap: { ...detailMap },
        };
      } else {
        newState = curState;
      }
      break;
    }
    default:
      newState = curState;
  }

  return newState;
};

export default DeviceDetailsReducer;
