import { DEVICE_ATTRIBUTE } from "./datakey.constant";

export const API_NAME = 'identx';

export const SUPER_ADMIN_EMAIL = process.env.REACT_APP_ADMIN_USER_EMAIL;

export const REGISTERATION_PASSWORD = process.env.REACT_APP_TEMP_REG_PASSWORD;

export const CUSTOM_FIELD_TYPE_ENUM = {
    Phone: 'Phone',
    Text: 'Text',
    Number: 'Number',
    List: 'List',
    Date: 'Date',
    MultiList: 'MultiList',
    LongText: 'LongText',
    Email: 'Email',
    Boolean: 'Boolean',
    URL: 'URL',
    Percentage: 'Percentage',
    Amount: 'Amount',
    AsyncListV2: 'AsyncListV2',
    SelectV2: 'SelectV2',
    TimeRange: 'TimeRange',
  };

export const MAX_MENU_HEIGHT_THREE_OPTIONS = 36 * 3;
export const MAX_MENU_HEIGHT_SIX_OPTIONS = 36 * 6;
export const MAX_MENU_HEIGHT_FOUR_OPTIONS = 36 * 4;

export const PASSWORD_CHECKS = [
  'Contain at least 8 characters',
  'Have at least one uppercase and at least one lowercase letter',
  'Have at least one number',
  'Have at least one special character (!,@,#,$,% etc.)',
  'Is not your sign in username/email',
];

export const PASSWORD_FORMAT_CHECK_ERROR_MESSAGE = 'Password does not satisfy format requirements';

export const COMPETITOR_DOMAINS = ['bureauid.com'];

export const EXEMPTED_STATUS_CODES = [200, 201, 202, 102, 432, 431, 433];

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const IS_NOT_PRODUCTION = API_BASE_URL !== 'https://api.identxlabs.com';

export const SHARED_OPTIONS_ENUM = [
  'Private to you',
  'Let others use it',
];

export const SHARED_OPTIONS = [
  { name: SHARED_OPTIONS_ENUM[0], label: 'Private to owner', value: SHARED_OPTIONS_ENUM[0] },
  { name: SHARED_OPTIONS_ENUM[1], label: SHARED_OPTIONS_ENUM[1], value: SHARED_OPTIONS_ENUM[1] },
];
Object.freeze(SHARED_OPTIONS);

export const DEVICE_TABLE_MANDATORY_COLUMNS = [
  {
    label: 'Identity',
    value: '_id',
    isSortable: false,
  },
];

export const DEVICE_TABLE_ALL_BASIC_COLUMNS = [
  {
    label: 'Device Brand',
    value: DEVICE_ATTRIBUTE.Device_Brand,
    isSortable: false,
  },
  {
    label: 'Session Id',
    value: DEVICE_ATTRIBUTE.Session_Id,
    isSortable: false,
  },
  {
    label: 'Carrier Country',
    value: DEVICE_ATTRIBUTE.Carrier_Country,
    isSortable: false,
  },
  {
    label: 'Risk Score',
    value: DEVICE_ATTRIBUTE.Risk_Score,
    isSortable: false,
  },
  {
    label: 'Rooted',
    value: 'Rooted',
    isSortable: false,
  },
  {
    label: 'Emulator',
    value: 'Emulator',
    isSortable: false,
  },
  {
    label: 'Geo Spoofing',
    value: DEVICE_ATTRIBUTE.Geo_Spoofing,
    isSortable: false,
  },
  {
    label: 'App Tampering',
    value: DEVICE_ATTRIBUTE.App_Tampering,
    isSortable: false,
  },
  {
    label: 'Auto Clicker',
    value: DEVICE_ATTRIBUTE.Auto_Clicker,
    isSortable: false,
  },
  {
    label: 'App Cloned',
    value: DEVICE_ATTRIBUTE.App_Cloned,
    isSortable: false,
  },
  {
    label: 'VPN Enabled',
    value: DEVICE_ATTRIBUTE.VPN_Enabled,
    isSortable: false,
  },
  {
    label: 'Jail Broken',
    value: DEVICE_ATTRIBUTE.Jail_Broken,
    isSortable: false,
  },
  {
    label: 'Factory Reset',
    value: DEVICE_ATTRIBUTE.Factory_Reset,
    isSortable: false,
  },
  {
    label: 'Virtual OS',
    value: DEVICE_ATTRIBUTE.Virtual_OS,
    isSortable: false,
  },
  {
    label: 'Hooking',
    value: 'Hooking',
    isSortable: false,
  },
  {
    label: 'Device Mask',
    value: DEVICE_ATTRIBUTE.Device_Mask,
    isSortable: false,
  },
  {
    label: 'Debugging',
    value: 'Debugging',
    isSortable: false,
  },
  {
    label: 'Screen Sharing',
    value: DEVICE_ATTRIBUTE.Screen_Sharing,
    isSortable: false,
  },
  {
    label: 'Last Seen',
    value: 'updatedAt',
    isSortable: true,
  },
  {
    label: 'Created At',
    value: 'createdAt',
    isSortable: true,
  },
];
export const DEVICE_TABLE_DEFAULT_COLUMNS = [
  {
    label: 'Last Seen',
    value: 'updatedAt',
    isSortable: true,
  },
  {
    label: 'Session ID',
    value: DEVICE_ATTRIBUTE.Session_Id,
    isSortable: false,
  },
  {
    label: 'XID',
    value: DEVICE_ATTRIBUTE.xId,
    isSortable: false,
  },
  {
    label: 'Make',
    value: DEVICE_ATTRIBUTE.Device_Brand,
    isSortable: false,
  },
  {
    label: 'Risk',
    value: DEVICE_ATTRIBUTE.Risk_Score,
    isSortable: true,
  },
];