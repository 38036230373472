export const RISK_FACTORS = {
    'Rooted': 'Rooted',
    'Emulator': 'Emulator',
    'Geo_Spoofing': 'Geo_Spoofing',
    // Dev note : Temporary disable
    // 'App_Tampering': 'App_Tampering',
    'Auto_Clicker': 'Auto_Clicker',
    'App_Cloned': 'App_Cloned',
    'VPN_Enabled': 'VPN_Enabled',
    'Jail_Broken': 'Jail_Broken',
    'Factory_Reset': 'Factory_Reset',
    'Virtual_OS': 'Virtual_OS',
    'Hooking': 'Hooking',
    'Device_Mask': 'Device_Mask',
    // 'Debugging': 'Debugging',
    'Screen_Sharing': 'Screen_Sharing',
    'Custom_ROM': 'Custom_ROM',
}

Object.freeze(RISK_FACTORS);

export const DEVICE_ATTRIBUTE = {
    // Device Info
    '_id': '_id',
    'xId': 'xId',
    'platform': 'platform',
    'Device_Brand': 'Device_Brand',
    'Device_Model': 'Device_Model',
    'Device_Name': 'Device_Name',
    'Device_Ram': 'Device_Ram',
    'Battery_Level': 'Battery_Level',
    'Battery_Temp': 'Battery_Temp',
    'Carrier_Name': 'Carrier_Name',
    'Carrier_Country': 'Carrier_Country',
    'Battery_Technology': 'Battery_Technology',
    'Battery_Voltage': 'Battery_Voltage',
    'Battery_Health': 'Battery_Health',
    'Carrier_Country_Code': 'Carrier_Country_Code',
    'Carrier_Network_Code': 'Carrier_Network_Code',
    'GPS_Coordinate': 'GPS_Coordinate',
    'IP_Country': 'IP_Country',
    'Screen_Size': 'Screen_Size',
    'Pixel_Density': 'Pixel_Density',
    'User_Language': 'User_Language',
    'IP_Address': 'IP_Address',
    'Time_Zone': 'Time_Zone',
    'Screen_Height': 'Screen_Height',
    'Screen_Breadth': 'Screen_Breadth',
    'CPU_Architecture': 'CPU_Architecture',
    'OS_Version': 'OS_Version',
    'Merchant_App_Version': 'Merchant_App_Version',
    'IdentX_SDK': 'IdentX_SDK',
    'Session_Id': 'Session_Id',
    'Latitude': 'Latitude',
    'Longitude': 'Longitude',
    // Risk Related
    'Risk_Score': 'Risk_Score',
    ...RISK_FACTORS,
};

Object.freeze(DEVICE_ATTRIBUTE);
