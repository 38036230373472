import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers';

const whitelist = [
  'User', // must
  'DeviceListTableColumn',
  'DeviceListFilter'
];

const persistConfig = {
  key: process.env.REACT_APP_WEB_URL || 'localhost',
  storage,
  whitelist, // which reducer want to store
};

/**
 * Logs all actions and states after they are dispatched.
 */
const logger = store => next => action => {
  console.group(action.type)
  console.info('dispatching', action)
  let result = next(action)
  console.log('next state', store.getState())
  console.groupEnd(action.type)
  return result
}

const pReducer = persistReducer(persistConfig, rootReducer);
// create store
const store = createStore(
  pReducer,
  applyMiddleware(logger, thunk),
);

const persistor = persistStore(store);
export { persistor, store };

