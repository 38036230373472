import { FETCH_USER, FETCH_USER_FAILURE, FETCH_USER_SUCCESS, UPDATE_USER } from "../../action/Account";
import { USER_ACCESS_DETAILS, USER_AUTHENTICATED } from "../../action/type";

const initialState = {
    data: {}, isLoading: NaN, error: '', isAuthenticated: false,
};
export const userReducer = (curState = initialState, action = { type: '', payload: '' }) => {
    let newState;
    switch (action.type) {
        case USER_AUTHENTICATED: {
            newState = {
                ...curState, isAuthenticated: true,
            };
            break;
        }
        case USER_ACCESS_DETAILS: {
            newState = {
                ...curState, data: action.payload,
            };
            break;
        }
        case FETCH_USER:
            newState = {
                ...curState, error: null, isLoading: true,
            };
            break;
        case FETCH_USER_SUCCESS:
            newState = {
                ...curState,
                data: action.payload,
                isLoading: false,
            };
            break;
        case FETCH_USER_FAILURE:
            newState = {
                ...curState, data: {}, error: action.payload,
                isLoading: false,
            };
            break;
        case UPDATE_USER:
            newState = {
                ...curState, data: { ...curState.data, ...action.payload }
            };
            break;
        default:
            newState = curState;
    }

    return newState;
};
