import { combineReducers } from 'redux';
import { USER_LOGGED_OUT } from '../action/type';
import { offlineReducer } from './offlineReducer';
import DeviceListReducer from './Device/list';
import DeviceListFilterReducer from './Device/filter';
import DeviceDetailsReducer from './Device/detailMap';
import { userReducer } from './Account/userReducer';
import { settingReducer } from './Account/settingReducer';
import { spinnerCounterReducer } from './spinnerReducer';
import { deviceActivityReducer } from './Device/deviceActivityReducer';
import { dashboardReducer } from './dashboardReducer';

const appReducer = combineReducers({
  User: userReducer,
  Setting: settingReducer,
  DeviceList: DeviceListReducer,
  DeviceListFilter: DeviceListFilterReducer,
  DeviceDetail: DeviceDetailsReducer,
  DeviceActivity: deviceActivityReducer,
  Offline: offlineReducer,
  SpinnerCounter: spinnerCounterReducer,
  Dashboard: dashboardReducer,
});

// when a logout action is dispatched it will reset redux state
const rootReducer = (state, action) => appReducer(
  action.type === USER_LOGGED_OUT ? undefined : state, action,
);

export default rootReducer;
