// get document coordinates of the element
function getCoords(elem) {
  const box = elem.getBoundingClientRect();

  return {
    top: box.top + window.pageYOffset,
    right: box.right + window.pageXOffset,
    bottom: box.bottom + window.pageYOffset,
    left: box.left + window.pageXOffset,
  };
}

// TODO: Prince possible memory leak dues to not clearing timeout as well
// as not removing eventlistener
const onDownloadClick = ({ location, id }) => {
  const a = document.createElement('a');
  a.id = id;
  a.href = location;
  a.download = 'download';
  const clickHandler = () => {
    setTimeout(() => {
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
};

export const PROMPT_MESSAGE_FOR_EMAIL_WITHOUT_SUBJECT_OR_BODY = 'Are you sure you want to send this email without a subject or body?';
export const PROMPT_MESSAGE = "You haven't saved the changes yet. If you leave now, your changes will be lost";
export const customPromptMessage = () => PROMPT_MESSAGE;

export const closeDropdownListener = (evt, elementIds, callback, classNames = []) => {
  let targetElement = evt.target; // clicked element
  let flyoutElements = elementIds.map((elementId) => document.getElementById(elementId));
  flyoutElements = flyoutElements.concat(classNames.map((className) => document.getElementsByClassName(className)[0]));
  do {
    if (flyoutElements.includes(targetElement)) {
      return;
    }
    targetElement = targetElement.parentNode;
  } while (targetElement);
  callback();
};

export { getCoords, onDownloadClick };
