import capitalize from "lodash.capitalize";
import { isFunction, isObject } from "./dataType.util";

/**
 * @function cleanObject - Function to clear object with null, undefined and '' values
 * @example
 * // returns { userName: 'example' }
 * cleanObject({ userName: 'example', email: null})
 * @returns {Object} Returns cleaned object
 */
export const cleanObject = (obj) => {
    if (typeof obj !== 'object' || Array.isArray(obj)
        || obj === null || obj === undefined) return obj;
    const newObj = { ...obj };
    Object.keys(newObj).forEach((propName) => {
        if (newObj[propName] === null || newObj[propName] === undefined || newObj[propName] === '') {
            delete newObj[propName];
        }
    });
    return newObj;
};

export const generateName = (data) => {
    if (!isObject(data)) return '';
    const { firstName = '', lastName = '', middleName = '' } = data;
    return ([firstName, lastName, middleName].filter((d) => d).map((d) => capitalize(d)).join(' ')).trim();
};

export const voidFunction = () => { };

export const fireEventOnEnter = (e, callback) => {
    if (e.keyCode === 13 && isFunction(callback)) {
        callback(e);
    }
};

export const guid = () => {
    function _p8(s) {
        const p = (`${Math.random().toString(16)}000000000`).substr(2, 8);
        return s ? `-${p.substr(0, 1)}-${p.substr(4, 5)}` : p.substring(0, 2);
    }
    return (_p8() + _p8(true) + new Date().toISOString().slice(0, 10)).replace(/-/g, '');
};


export const makeOptions = (val) => ({ value: val, label: val });
export const makeAllOptions = (arr) => (arr || []).map((d) => makeOptions(d));

export const findAndApply = (val, list) => {
    const found = (list || []).find((d) => d.value === val);
    return found || null;
};

export const isValidPassword = (password, email) => {
    const isValidLength = password.length > 7;
    const pattern = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$');
    return isValidLength && pattern.test(password) && email?.toLowerCase() !== password?.toLowerCase();
};


export const getCodeAndUsernameForPassReset = (url) => {
    const splittedArr = url.split('&');
    return { confirmationCode: splittedArr[0]?.split('code=')[1], confirmationEmail: splittedArr[splittedArr.length - 1]?.split('email=')[1] };
};

export const getCodeAndUsername = (url) => {
    const splittedArr = url.split('&');
    return { confirmationCode: splittedArr[0]?.split('code=')[1], confirmationEmail: splittedArr[splittedArr.length - 2]?.split('email=')[1], confirmationType: splittedArr[splittedArr.length - 1]?.split('confirmationType=')[1] };
};

export const getEmailAndSource = (url) => {
    const splittedArr = url.split('&');
    return { confirmationEmail: splittedArr[0]?.split('email=')[1], source: splittedArr[1]?.split('source=')[1] };
};

export const getQueryObject = (query) => new URLSearchParams(query);

export const debounce = (func, delay) => {
    let inDebounce;
    // eslint-disable-next-line func-names
    return function () {
        const context = this;
        // eslint-disable-next-line prefer-rest-params
        const args = arguments;
        clearTimeout(inDebounce);
        inDebounce = setTimeout(() => func.apply(context, args), delay);
    };
};

export function globalFilterFunction({ items, globalSearch, fields }) {
    const processedItems = [];
    if (!(globalSearch && globalSearch.trim())) return items;
    items.forEach((item) => {
        for (let index = 0; index < fields.length; index += 1) {
            if (item[fields[index]] && String(item[fields[index]]).toLowerCase().includes(globalSearch.toLowerCase())) {
                processedItems.push(item);
                break;
            }
        }
    });
    return processedItems;
}

export const isUserAdmin = (user) => {
    let isAdmin = false;
    if (isObject(user) && user.roleAndProfile) {
        const { governanceProfile } = user.roleAndProfile;
        if (governanceProfile.toLowerCase() === 'admin') isAdmin = true;
    }
    return isAdmin;
};

export const generateAssignee = (ownerOptions, currentUser) => {
    if (isObject(ownerOptions)) {
        const userCurrent = {};
        Object.keys(ownerOptions).map((owner) => {
            if (currentUser === ownerOptions[owner].basicInfo.email) {
                userCurrent.label = generateName(ownerOptions[owner].basicInfo);
                userCurrent.value = owner;
            }
            return null;
        });
        return userCurrent;
    }
    return null;
};

export const generateLoggedInUserAssignee = (ownerOptions, currentUser) => {
    const resp = generateAssignee(ownerOptions, currentUser);
    if (resp) resp.label = `${resp.label} (You)`;
    return resp;
};

export const isUserVerifiedAndActive = ({ isActive, isVerified }) => isActive && isVerified;

const createUserOption = ({ user, reqOnlyActiveUsers, loggedInUserId }) => {
    if (reqOnlyActiveUsers ? (isUserVerifiedAndActive(user)) : user.isVerified) {
      return {
        label: `${generateName(user.basicInfo)}${user.id === loggedInUserId ? ' (You)' : ''}`,
        value: user._id,
      };
    }
    return null;
  };


export const generateOwnerOptionsFromMap = ({ userMap = {}, userId, reqOnlyActiveUsers = true }) => {
    const activeUsers = [];
    Object.values(userMap).forEach((user) => {
      const option = createUserOption({
        user, reqOnlyActiveUsers, loggedInUserId: userId,
      });
      option && activeUsers.push(option);
    });
    return activeUsers;
  };

export const escapeTextValue = (textValue = '') => `${textValue}`.replace(/&/g, '&amp;')
  .replace(/</g, '&lt;')
  .replace(/>/g, '&gt;')
  .replace(/"/g, '&quot;')
  .replace(/'/g, '&#x27');
// .replace(/\//g, '&#x2F');

export const generateProfileTagName = (profileName = '') => {
    let initials = profileName.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  };

  export const getClippedString = (str = '', length = 24) => {
    let modStr = str;
    if (str.length > length) {
      modStr = `${str.slice(0, length - 3)}...`;
    }
    return modStr;
  };

export const isPluralQuantity = (count) => (count > 1 ? 's' : '');

export const noOptionsMessageHelperForSearch = (fieldType) => `Type and search ${fieldType.toLowerCase()}`;

export const getRiskLabel = (riskThreshold, value) => {
    
    let label = 'No Risk';
    // eslint-disable-next-line array-callback-return
    const { low, medium, high } = riskThreshold;
    if (value >= low?.min && value <= low?.max) {
      label = 'low';
    } else if (value >= medium?.min && value <= medium?.max) {
      label = 'medium';
    } else if (value >= high?.min) {
      label = 'high';
    }
    return label;
  };

  export const convertNumberToCurrencyFormat = ({
    currency = 'USD', numberToBeFormatted = 0, compactNotation = true, extraProp = {},
  }) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      notation: compactNotation ? 'compact' : 'standard',
      ...extraProp,
    });
    let returnValue = formatter.format(numberToBeFormatted);
    returnValue = returnValue.replace('$', '');
    return returnValue;
  };