import React, { Component } from 'react';
import { logError } from '../../libs/errorLib';
import { openHelpCrunchChatWindow } from '../../libs/helpcrunch';
import './index.scss';

// Note: Do not use PureComponent Here
export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: !!error };
  }

  componentDidCatch(error, errorInfo) {
    logError(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      // TO-DO: On Click of button trigger an emain for IdentxLabs support
      return (
        <div className="ErrorBoundary">
          <h2>
            Sorry there was a problem loading this page. Please click
            <span
              className="highlight cursor-pointer marginLeftFour marginRightFour"
              onClick={openHelpCrunchChatWindow}
              onKeyDown={openHelpCrunchChatWindow}
              tabIndex={0}
              role="button"
            >
              here

            </span>
            to report the issue.
          </h2>
        </div>
      );
    }

    return children;
  }
}
