import React, { memo } from 'react';
import { Tooltip } from 'react-tooltip';
import { MATERIAL_ICON, COLORICONS } from './material.icons';
import { voidFunction } from '../../../utils';
import './index.scss';

export default function MaterialIcon({
  icon, style = {}, className = '', onClick = voidFunction, id, isDisabledIcon = false, onKeyDown = voidFunction,
  type = 'symbol',
}) {
  return (
    <span
      id={id}
      className={`${type === 'material' ? 'material-icons' : 'material-symbols-outlined'} fontvlg ${isDisabledIcon ? 'noPointerEvents' : 'cursor-pointer'} ${className}`}
      style={{
        textTransform: 'lowercase',
        userSelect: 'none',
        outline: 'none',
        ...style,
        color: isDisabledIcon ? '#c4c4c4' : (style.color || COLORICONS[icon] || COLORICONS.default),
      }}
      onKeyDown={onKeyDown}
      onClick={onClick}
      role="tab"
      tabIndex={0}
    >
      {MATERIAL_ICON[icon] || icon}
    </span>
  );
}

export const MaterialIconWithTooltip = memo(({
  icon, className = '', onClick, isDisabled = false, iconStyle = {},
  tooltipId = '', showTooltip = false, tooltipText = '', materialIconClass = '', place = 'left',
  isImage = false,
}) => (
  <>
    {showTooltip && (
      <Tooltip
        effect="solid"
        id={tooltipId}
        place={place}
      />
    )}
    <span
      className={className}
      data-tip={tooltipText}
      data-for={tooltipId}
    >
      {
        // eslint-disable-next-line
        isImage ? <img src={icon} alt={tooltipText} onKeyDown={null} onClick={onClick} style={iconStyle} className={materialIconClass} /> : <MaterialIcon icon={icon} onClick={onClick} isDisabledIcon={isDisabled} style={iconStyle} className={materialIconClass} />
      }
    </span>
  </>
));